<style scoped>
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-label {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  display: inline-block;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

.toggle-label::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background: white;
  border-radius: 50%;
  transition: 0.3s;
}

input:checked + .toggle-label {
  background: #4caf50;
}

input:checked + .toggle-label::after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}


.table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    border: 1px solid #ccc; /* Optional: border to define scrollable area */
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}

.search-table {
    width: 100%;
    border-collapse: collapse;
}

.search-table th, .search-table td {
    padding: 8px;
    text-align: left;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">
        <div class="row">
            <div class="col-lg-12">
                    
                <div class="card">
                    <div class="p-4 d-flex" style="justify-content: space-between; font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Token</div>
                        <div>
                            <button
                            @click="saveToken()"
                            class="btn btn-color"
                            type="button"
                            style="
                                width: 100%;
                                color: #fefefe;
                                background-color: #04318c !important;
                                border-color: #04318c !important;
                            "
                        >
                            Save
                        </button>
                        </div>
                    </div>
                    
                    <div class="pt-4 pe-4" style="font-weight: 500; padding-left:10px">
                        <div class="ps-4">Exchange RM to Token. This will be made available to customers who puchase token through system.</div>
                    </div>

                    <div class="search-table-outter wrapper p-4">
                        <div class="row">
                            <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                                <label class="sr-only" for="inlineFormInputGroup">Token</label>
                                <div class="input-group mb-2">
                                    <input v-model="this.token_value" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Token">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Token</div>
                                    </div>
                                </div>
                            </div>
                                
                            <div class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex" style="justify-content: center; align-items: center;">=</div>

                            <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                                <label class="sr-only" for="inlineFormInputGroup">RM</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">RM</div>
                                    </div>
                                    <input v-model="this.token_rm" type="text" class="form-control" id="inlineFormInputGroup" placeholder="100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="p-4 d-flex" style="justify-content: space-between; font-size: 1.2rem; font-weight: 500; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Points</div>
                        <div>
                            <button
                            @click="savePoint()"
                            class="btn btn-color"
                            type="button"
                            style="
                                width: 100%;
                                color: #fefefe;
                                background-color: #04318c !important;
                                border-color: #04318c !important;
                            "
                        >
                            Save
                        </button>
                        </div>
                    </div>
                    
                    <div class="pt-4 pe-4" style="padding-left: 10px; font-weight: 500;">
                        <div class="ps-4">Exchange point to token. User can be earned point through activities below.</div>
                    </div>

                    <div class="search-table-outter wrapper p-4">
                        <div class="row">
                            <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                                <label class="sr-only" for="inlineFormInputGroup">Point</label>
                                <div class="input-group mb-2">
                                    <input v-model="this.point_value" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Point">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Point</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex" style="justify-content: center; align-items: center;">=</div>
                            
                            <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                                <label class="sr-only" for="inlineFormInputGroup">Token</label>
                                <div class="input-group mb-2">
                                    <input v-model="this.point_token" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Token">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Token</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="p-4 d-flex" style="justify-content: space-between; font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Activity Collect Points</div>
                        <div>
                            <button
                            @click="saveActivity()"
                            class="btn btn-color"
                            type="button"
                            style="
                                width: 100%;
                                color: #fefefe;
                                background-color: #04318c !important;
                                border-color: #04318c !important;
                            "
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div class="pt-4 pe-4" style="padding-left: 10px; font-weight: 500;">
                        <div class="ps-4">Select Pages to be enable for point collections.</div>
                    </div>
                
                    <div class="search-table-outter wrapper p-4">
                        <div class="table-container">
                            <table class="search-table inner">
                                <tr style="height: 50px; border-bottom: 1px solid #cccccc">
                                    <th style="min-width: 50px;">#</th>
                                    <th style="min-width: 200px;text-align:left">Pages</th>
                                    <th style="min-width: 200px;text-align:left">Limit per day (Point)</th>
                                    <th style="min-width: 250px;text-align: left">User Action</th>
                                    <th style="min-width: 170px;text-align: left">Status</th>
                                </tr>
                                <tr style="height: 60px">
                                    <td> 1.</td>
                                    <td>Listing</td>
                                    <td>
                                        <input style="width: 150px" v-model="this.pointActivity1" class="form-control" type="text" placeholder="eg: 1" required />
                                    </td>
                                    <td style="min-width: 150px">
                                        <select style="width: 200px" v-model="this.activity1" class="form-select form-control-primary" name="select">
                                            <option value="" disabled selected> Select Action </option>
                                            <option value="Open">Open</option>
                                            <option value="Scroll">Scroll</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="toggle-container">
                                            <input type="checkbox" id="toggle-switch1" v-model="isOn1" />
                                            <label for="toggle-switch1" class="toggle-label"></label>
                                            <p>Status: {{ isOn1 ? 'On' : 'Off' }}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="height: 60px">
                                    <td> 2.</td>
                                    <td>Listing Details</td>
                                    <td>
                                        <input style="width: 150px" v-model="this.pointActivity2" class="form-control" type="text" placeholder="eg: 1" required />
                                    </td>
                                    <td style="min-width: 150px">
                                        <select style="width: 200px" v-model="this.activity2" class="form-select form-control-primary" name="select">
                                            <option value="" disabled selected> Select Action </option>
                                            <option value="Open">Open</option>
                                            <option value="Scroll">Scroll</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="toggle-container">
                                            <input type="checkbox" id="toggle-switch2" v-model="isOn2" />
                                            <label for="toggle-switch2" class="toggle-label"></label>
                                            <p>Status: {{ isOn2 ? 'On' : 'Off' }}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="height: 60px">
                                    <td> 3.</td>
                                    <td>About Us</td>
                                    <td>
                                        <input style="width: 150px" v-model="this.pointActivity3" class="form-control" type="text" placeholder="eg: 1" required />
                                    </td>
                                    <td style="min-width: 150px">
                                        <select style="width: 200px" v-model="this.activity3" class="form-select form-control-primary" name="select">
                                            <option value="" disabled selected> Select Action </option>
                                            <option value="Open">Open</option>
                                            <option value="Scroll">Scroll</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="toggle-container">
                                            <input type="checkbox" id="toggle-switch3" v-model="isOn3" />
                                            <label for="toggle-switch3" class="toggle-label"></label>
                                            <p>Status: {{ isOn3 ? 'On' : 'Off' }}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="height: 60px">
                                    <td> 4.</td>
                                    <td>List of Agent</td>
                                    <td>
                                        <input style="width: 150px" v-model="this.pointActivity4" class="form-control" type="text" placeholder="eg: 1" required />
                                    </td>
                                    <td style="min-width: 150px">
                                        <select style="width: 200px" v-model="this.activity4" class="form-select form-control-primary" name="select">
                                            <option value="" disabled selected> Select Action </option>
                                            <option value="Open">Open</option>
                                            <option value="Scroll">Scroll</option>
                                            <option value="Whatsapp">Whatsapp</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="toggle-container">
                                            <input type="checkbox" id="toggle-switch4" v-model="isOn4" />
                                            <label for="toggle-switch4" class="toggle-label"></label>
                                            <p>Status: {{ isOn4 ? 'On' : 'Off' }}</p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {
    getPointCompany,
    savePointCompany,
    getTokenCompany,
    saveTokenCompany,
    getActivityPoint,
    saveActivityPoint,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Exchange Rates",

    data() {
        return {
            pointActivity1: null,
            pointActivity2: null,
            pointActivity3: null,
            pointActivity4: null,
            activity1: null,
            activity2: null,
            activity3: null,
            activity4: null,
            isOn1: false,
            isOn2: false,
            isOn3: false,
            isOn4: false,
            point_value: null,
            point_token: null,
            token_value: null,
            token_rm: null,
        }
    },
    
    beforeMount() {
        this.getPoint();
        this.getToken();
        this.getActivity();
    },
        
    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
 
        async getPoint() {
            try {
                await getPointCompany().then((response) => {
                    if (response.data.success === true) {
                        this.point_value = response.data.data.value;
                        this.point_token = response.data.data.equivalent;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async savePoint() {
            const dataPass = {
                value: this.point_value,
                equivalent: this.point_token,
            };
            try {
                await savePointCompany(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("success");
                        this.getPoint();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async getToken() {
            try {
                await getTokenCompany().then((response) => {
                    if (response.data.success === true) {
                        this.token_value = response.data.data.value;
                        this.token_rm = response.data.data.price;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async saveToken() {
            const dataPass = {
                value: this.token_value,
                price: this.token_rm,
            };
            try {
                await saveTokenCompany(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("success");
                        this.getToken();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }

        },

        async getActivity() {
            try {
                await getActivityPoint().then((response) => {
                    if (response.data.success === true) {
                        this.pointActivity1 = response.data.data[0].limit_per_day;
                        this.pointActivity2 = response.data.data[1].limit_per_day;
                        this.pointActivity3 = response.data.data[2].limit_per_day;
                        this.pointActivity4 = response.data.data[3].limit_per_day;
                        this.activity1 = response.data.data[0].user_action;
                        this.activity2 = response.data.data[1].user_action;
                        this.activity3 = response.data.data[2].user_action;
                        this.activity4 = response.data.data[3].user_action;
                        this.isOn1 = response.data.data[0].status=='1'? true : false;
                        this.isOn2 = response.data.data[1].status=='1'? true : false;
                        this.isOn3 = response.data.data[2].status=='1'? true : false;
                        this.isOn4 = response.data.data[3].status=='1'? true : false;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async saveActivity() {
            const dataPass = [
                {
                    id: "1",
                    pages: "Listing",
                    limit_per_day: this.pointActivity1,
                    user_action: this.activity1,
                    status: this.isOn1==true? 1 : 0,
                },
                {
                    id: "2",
                    pages: "Listing Details",
                    limit_per_day: this.pointActivity2,
                    user_action: this.activity2,
                    status: this.isOn2==true? 1 : 0,
                },
                {
                    id: "3",
                    pages: "About Us",
                    limit_per_day: this.pointActivity3,
                    user_action: this.activity3,
                    status: this.isOn3==true? 1 : 0,
                },
                {
                    id: "4",
                    pages: "List of Agent",
                    limit_per_day: this.pointActivity4,
                    user_action: this.activity4,
                    status: this.isOn4==true? 1 : 0,
                },
            ];
            try {
                await saveActivityPoint(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("success");
                        this.getActivity();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    }
}
</script>
