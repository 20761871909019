<template>
    <li class="pointer" v-if="this.user.check">
        <a @click="route1()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">Home</h6></a>
    </li>
    <li class="pointer" v-if="this.user.check">
        <a @click="route2()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">Search Properties</h6></a>
    </li>
    <li class="pointer" v-if="this.user.check">
        <a @click="route3()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">List of Agent</h6></a>
    </li>
    <li class="pointer" v-if="this.user.check">
        <a @click="route4()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">About Us</h6></a>
    </li>
    <li class="pointer" v-if="this.user.check">
        <a @click="route5()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">Contact Us</h6></a>
    </li>
    <li class="pointer" v-if="this.user.login">
        <a @click="route6()"><h6 style="font-size: 0.9rem !important; padding-top: 10px">Login</h6></a>
    </li>
    <li class="profile-nav onhover-dropdown pe-0 py-0" v-if="!this.user.login">
        <div class="media profile-media">
            <img style="border-radius:50%; width: 40px; height: 40px;" :src="this.profile_image_header" />
            <div class="media-body">
                <span>{{ this.user.name.value }}</span>
                <p class="mb-0"> {{ this.user.role.value }} <i class="middle fa fa-angle-down"></i></p>
            </div>
        </div>
        <ul class="profile-dropdown onhover-show-div">
            <li>
                <router-link :to="'/user/profile'"
                    ><vue-feather type="user"></vue-feather
                    ><span>My Profile</span></router-link
                >
            </li>
            <li v-if="this.user.check">
                <router-link :to="'/dashboard/stats'"
                    ><vue-feather type="home"></vue-feather
                    ><span>Dashboard </span></router-link
                >
            </li>
            <li v-if="!this.user.check">
                <router-link :to="'/web/homepage'"
                    ><vue-feather type="airplay"></vue-feather
                    ><span>Homepage</span></router-link
                >
            </li>
            <li>
                <a @click="userLogout()"><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
            </li>
        </ul>
    </li>
</template>
<style scoped>
.pointer{
    cursor: pointer;
}
</style>
<script>
import { logout } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Profile",

    data() {
        return {
            profile_image_header: null,
            user: {
                name: {
                    value: localStorage.getItem("username"),
                },
                role: {
                    value: localStorage.getItem("role"),
                },
                login: true,
                check: true,
            },
        };
    },

    created() {
        this.checkLogin();
        this.setProfile();
    },

    methods: {
        setProfile() {
            if (localStorage.getItem("image") == "null") {
                this.profile_image_header = require('../../assets/images/user/avatar.png');
            } else {
                this.profile_image_header = localStorage.getItem("image");
            }
        },
        checkLogin() {
            if (localStorage.getItem("token_credential") != undefined) {
                this.user.login = false;
            } else {
                this.user.login = true;
            }
            const path = [
                "/web/homepage", 
                "/web/search", 
                "/web/list-of-agent",
                "/web/aboutus",
                "/web/joinus",
                "/web/contactus"];

            if (path.includes(this.$route.path)) {
                this.user.check = true;
            } else {
                this.user.check = false;
            }
        },
        route1() {
            this.$router.push("/web/homepage");
        },
        route2() {
            this.$router.push("/web/search");
        },
        route3() {
            this.$router.push("/web/list-of-agent");
        },
        route4() {
            this.$router.push("/web/aboutus");
        },
        route5() {
            this.$router.push("/web/contactus");
        },
        route6() {
            this.$router.push("/auth/login");
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position : "bottom-right"
            });
        },

        async userLogout() {
            try {
                await logout().then((response) => {
                    if (response.data.success === true) {
                        localStorage.removeItem("token_expired");
                        localStorage.removeItem("token_credential");
                        localStorage.removeItem("access");
                        this.$router.push("/web/homepage");
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
