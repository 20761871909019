<style scoped>
.topup-window {
        border: 1px #CCCCCC solid;
        padding: 20px;
        margin: 20px;
        border-radius: 10px;
        cursor: pointer;
    }

    .topup-selected {
        background: #CA952B;
        color: #f3f4f6;
    }

    .form-control::placeholder {
        color: #d9d9d9 !important;
    }

    .padding-container {
        padding: 1.5rem;
    }

    @media (max-width: 575px) {
        .padding-container {
            padding: 1.5rem 0.4rem 1.5rem 0.4rem;
        }
    }
</style>

<template>
    <div class="padding-container">
        <h4 class="py-3 m-0">My Balance</h4>

        <div class="card rounded p-4">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 d-flex"
                    style="justify-content: space-between">
                    <div class="" style="font-weight: 450;" align="left">My Tokens:</div>
                    <div class="">
                        <button class="btn" type="button" @click="clearModalToken()" data-bs-toggle="modal"
                            data-bs-target="#addToken"
                            style="color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">
                            Topup
                        </button>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3"
                    style="font-size: 1.5rem; font-weight:500;">{{ this . myToken }} tokens</div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 d-flex"
                    style="justify-content: space-between">
                    <div class="" style="font-weight: 450;" align="left">My Points:</div>
                    <div class="">
                        <button class="btn" type="button" @click="clearModalPoint()" data-bs-toggle="modal"
                            data-bs-target="#convertPoint"
                            style="color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">
                            Convert
                        </button>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3"
                    style="font-size: 1.5rem; font-weight:500;">{{ this . myPoint }} points</div>
            </div>
        </div>

        <div class="modal fade modal-bookmark" id="addToken" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Topup Token</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;">
                        <div class="modal-body row" style="justify-content: space-between">
                            <div class="py-2 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Choose Amount:</div>

                                <div class="d-flex">
                                    <div @click="userSelectAmount('1', '10')" class="topup-window"
                                        :class="selected_amount == 1 ? 'topup-selected' : ''">RM 10</div>
                                    <div @click="userSelectAmount('2', '20')" class="topup-window"
                                        :class="selected_amount == 2 ? 'topup-selected' : ''">RM 20</div>
                                    <div @click="userSelectAmount('3', '50')" class="topup-window"
                                        :class="selected_amount == 3 ? 'topup-selected' : ''">RM 50</div>
                                    <div @click="userSelectAmount('4', '100')" class="topup-window"
                                        :class="selected_amount == 4 ? 'topup-selected' : ''">RM 100</div>
                                </div>
                                <input v-model="this.topupAmount" class="form-control" type="hidden" placeholder=""
                                    required />
                            </div>
                            <div class="py-1 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Convert to:</div>
                                <div style="margin: 20px; font-size: 1.5rem; font-weight: 500">
                                    {{ this . convertedToken }}
                                    tokens</div>
                            </div>
                            <div class="py-2 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Select Bank:</div>
                                <select v-model="selectedBank" class="form-control" required>
                                    <option value="">Choose Bank</option>
                                    <option v-for="bank in bankList" :key="bank.bank_code" :value="bank.bank_code">
                                        {{ bank . bank_name }}
                                    </option>
                                </select>
                            </div>


                        </div>
                    </div>
                <div class="row p-3">
                    <div class="col-12 d-flex" style="justify-content: flex-end">
                        <div>
                            <button @click="checkoutToken()" data-bs-dismiss="modal" class="btn btn-color"
                                type="button"
                                :disabled="!topupAmount || !selectedBank"
                                style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                Checkout
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-bookmark" id="convertPoint" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Convert Point to Token</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;">
                        <div class="modal-body row" style="justify-content: space-between">
                            <div class="py-2 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Choose Amount:</div>
                                <div class="container mt-4">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <!-- Decrement Button -->
                                            <button class="btn btn-secondary"
                                                style="color: #fefefe; background-color: #CA952B !important; border-color: #CA952B"
                                                @click="decrement">
                                                -
                                            </button>
                                        </div>
                                        <div class="col-auto">
                                            <!-- Number Input -->
                                            <input type="number" class="form-control" v-model.number="counter"
                                                @input="handleInput" min="0" style="width: 100px;" />
                                        </div>
                                        <div class="col-auto">
                                            <!-- Increment Button -->
                                            <button class="btn btn-secondary"
                                                style="color: #fefefe; background-color: #CA952B !important; border-color: #CA952B"
                                                @click="increment">
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <input v-model="this.pointAmount" class="form-control" type="hidden" placeholder=""
                                    required />
                            </div>
                            <div class="pt-4 py-2 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Convert to:</div>
                                <div style="margin: 20px; font-size: 1.5rem; font-weight: 500">
                                    {{ this . convertedPoints }} Token</div>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button @click="convertPointUser()" data-bs-dismiss="modal" class="btn btn-color"
                                    type="button"
                                    style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Convert
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        paymentToken,
        saveConvertPoint,
        getTokenCompany,
        getPointCompany,
        userProfile,
        getListBank,
    } from "@/helper/apiservice/axios-http";
    import {
        toast
    } from "vue3-toastify";

    export default {
        name: "My Balance",

        data() {
            return {
                myToken: 0,
                myPoint: 0,

                selected_amount: 0,
                convertedToken: 0,
                token_rm: 0,
                token_value: 0,
                topupAmount: 0,

                counter: 0,
                point_value: 0,
                point_token: 0,
                convertedPoints: 0,
                selectedBank: "",
                bankList: [],
            };
        },

        beforeMount() {
            this.loadProfileDetails();
            this.getToken();
            this.getPoint();
            this.loadBankList();
        },

        mounted() {},

        created() {},

        computed: {},

        methods: {
            async loadBankList() {
                try {
                    const response = await getListBank();
                    console.log(response);

                    // Memeriksa jika response sukses dan status 200
                    if (response.data.success===true) {
                        this.bankList = response.data.data; // Bank data diresponse.data
                        console.log(response.data.data);
                        
                    } else {
                        this.notificationError("Failed to load bank list.");
                    }
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },
            userSelectAmount(number, amount) {
                this.selected_amount = number;
                this.convertedToken = parseFloat(((amount / this.token_rm) * this.token_value).toFixed(2));
                this.topupAmount = amount;
            },
            increment() {
                if (this.counter == this.myPoint) {

                } else {
                    this.counter++;
                }
                this.convertedPoints = parseFloat(((this.counter / this.point_value) * this.point_token).toFixed(2));
            },
            decrement() {
                if (this.counter > 0) {
                    this.counter--;
                }
                this.convertedPoints = parseFloat(((this.counter / this.point_value) * this.point_token).toFixed(2));

            },
            handleInput(event) {
                if (event.target.value < 0) {
                    this.counter = 0;
                }
            },

            async checkoutToken() {
                try {
                    const dataPass = {
                        user_id: localStorage.getItem("user_id"),
                        amountToken: this.convertedToken,
                        amountPrice: this.topupAmount,
                        billplzBankCode: this.selectedBank,
                    };
                    await paymentToken(dataPass).then((response) => {
                        console.log(response.data.data);
                        
                        if (response.data.success == true) {
                            window.location.replace(response.data.data.url);

                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            async convertPointUser() {
                try {
                    const dataPass = {
                        agentId: localStorage.getItem("user_id"),
                        token: this.convertedPoints,
                        pointusage: this.counter
                    };

                    await saveConvertPoint(dataPass).then((response) => {
                        if (response.data.success === true) {
                            this.counter = 0;
                            this.loadProfileDetails();
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    // console.log(error);
                    this.notificationError("Something went wrong!");
                }
            },

            async loadProfileDetails() {
                try {
                    const dataPass = {
                        user_id: localStorage.getItem("user_id"),
                    };
                    await userProfile(dataPass).then((response) => {
                        if (response.data.success == true) {
                            this.myToken = response.data.data.token;
                            this.myPoint = response.data.data.point;
                            localStorage.setItem("image", response.data.data.image);

                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            async getToken() {
                try {
                    await getTokenCompany().then((response) => {
                        if (response.data.success === true) {
                            this.token_value = response.data.data.value;
                            this.token_rm = response.data.data.price;
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            clearModalToken() {
                this.selected_amount = 0;
                this.convertedToken = 0;
                this.topupAmount = null;
            },


            async getPoint() {
                try {
                    await getPointCompany().then((response) => {
                        if (response.data.success === true) {
                            this.point_value = response.data.data.value;
                            this.point_token = response.data.data.equivalent;
                        } else {
                            this.notificationError("Something went wrong!");
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },

            clearModalPoint() {
                this.pointAmount = null,
                    this.convertedPoints = 0;
            },

            // uiux
            notificationError(message) {
                toast(message, {
                    theme: "colored",
                    type: "success",
                    position:"bottom-right"
                });
            },
            notificationWarning(message) {
                toast(message, {
                    theme: "colored",
                    type: "success",
                    position:"bottom-right"
                });
            },
            notificationSuccess(message) {
                toast(message, {
                    theme: "colored",
                    type: "success",
                    position:"bottom-right"
                });
            },
        },
    };
</script>
