<style scoped>
.carousel-container {
    position: relative;
    width: 100%;
}
.carousel-wrapper {
    height: 250px;
    width: 272px;
    overflow: hidden;
    position: relative;
}
.carousel-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}
.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}
.carousel-control.left {
    left: 10px;
}
.carousel-control.right {
    right: 10px;
}
.carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.carousel-indicators span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: grey;
    border-radius: 50%;
    cursor: pointer;
}
.carousel-indicators .active {
    background-color: black;
}
.listing-card {
    flex-direction: row;
}
.border-image-corousel {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.button-position {
    justify-content: flex-start;
}
.button-position-approve {
    justify-content: flex-end;
}
.btn-size-approve {
    width: 100px;
    height:100%;
}
.button-action-list {
    width: 100%;
    height: 35px;
    font-size: 0.7rem;
}
.container-button-action {
    width: auto;
}
@media (max-width: 992px) {
    .listing-card {
        flex-direction: column;
    }
    .carousel-wrapper {
        width: 100%;
    }
    .border-image-corousel {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
    }
    .button-position {
        justify-content: center;
    }
    .border-tab-line2 {
        border-right: none !important;
    }
    .border-tab-line3 {
        border-top:1px solid rgb(187, 187, 187,0.5) !important;
    }
    .container-button-action {
        width: 40%;
    }
}
.padding-container {
    padding: 1.5rem;
}
@media (max-width: 575px) {
    .button-position-approve {
        padding-top: 10px;
        justify-content: space-around
    }
    .btn-size-approve {
        width: 100%;
    }
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}

/* listing detail */
.border-tab-line1 {
    border-right:1px solid rgb(187, 187, 187,0.5);
}
.border-tab-line2 {
    border-right:1px solid rgb(187, 187, 187,0.5);
}
.border-tab-line3 {
    border-top: none;
}
.border-tab {
    border-bottom: 2px solid #C89835 !important;
}
#popupToggle {
    display: none;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.popup {
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    text-align: center;
}
.close-btn {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px;
    background: #C89835;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    position:fixed;
    top: 20px;
    right: 76px;
}
#popupToggle:checked + .overlay {
    display: flex;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
</style>

<template>
    <div class="padding-container">
        <!-- main page -->
        <div v-if="this.pageView == 'All'">
            <div class="p-4 card">
                <div class="row" style="justify-content: space-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="align-items: center;">
                        <span style="font-size: 1.2rem; font-weight: 500;">Approval Listing</span>
                    </div>
                    
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search..." aria-label="Search" aria-describedby="button-addon2" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;" v-model="this.searchListing" />
                        </div>
                        <div>
                            <button class="btn btn-outline-primary" type="button" id="button-addon2" @click="performSearch()" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                <div style="margin-top:5px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="this.landListingLoader == false">
                <div v-if="this.landListing.length != 0">
                    <div class="d-flex" style="justify-content: center" v-for="(item, index) in this.landListing" :key="index">
                        <div class="card p-0 listing-card" style="width: 100%;">
                            
                            <div style="min-width: 272px;">
                                <div class="carousel-container">
                                    <transition-group name="fade" tag="div" class="carousel-wrapper">
                                        <img v-for="(image, imageIndex) in item.images" :key="imageIndex" 
                                        :src="image.image" v-show="currentIndexes[index] === imageIndex" class="carousel-image border-image-corousel" />
                                    </transition-group>

                                    <button @click="prev(index)" class="carousel-control left">&lt;</button>
                                    <button @click="next(index)" class="carousel-control right">&gt;</button>

                                    <div class="carousel-indicators">
                                        <span
                                            v-for="(image, imageIndex) in item.images"
                                            :key="imageIndex"
                                            :class="{ active: currentIndexes[index] === imageIndex }"
                                            @click="setIndex(index, imageIndex)"
                                        ></span>
                                    </div>
                                </div>
                            </div>

                            <div class="p-3" style="width: 100%;">
                                <div class="d-flex" style="flex-direction: column; justify-content: space-between; height: 100%; width: 100%">
                                    <div>
                                        <div style="font-size: 1.1rem; font-weight: 400; overflow-wrap: break-word;">{{ item.land_title }}</div>
                                        <div class="pt-2" style="font-size: 0.7rem; font-weight: 300; overflow-wrap: break-word;">{{ item.city }} {{ item.state }}</div>
                                        <div class="pt-2" style="font-size: 0.7rem; font-weight: 300">{{ item.public == 1? 'Listed at' : 'Save as Draft at' }} {{ convertTime(item.created_at) }}</div>
                                        <div class="pt-2" style="font-size: 1.2rem; font-weight: 450">RM {{ parseFloat(item.land_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                                    </div>

                                    <div class="row pt-3">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-2 pe-2">
                                            <button @click="viewLandApproval(item.id)" class="btn btn-tertiary btn-block p-2 button-action-list" style="border: #ccc solid 1px">View</button>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-2 pe-2">
                                            <button @click="saveLandApproval(item.id)" class="btn btn-primary btn-block p-2 button-action-list">Approve</button>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 py-2 pe-2">
                                            <button @click="openFeedback(item.id)" data-bs-toggle="modal" data-bs-target="#rejectBox" class="btn btn-danger btn-block p-2 button-action-list">Reject</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                    <div>
                        <img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
                    </div>
                    <div style="text-align: center;">
                        <h6>No result found!</h6>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex" style="flex-direction: column; justify-content: center;">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- detail posting -->
        <div v-if="this.pageView == 'detail'">
            <div class="p-4 card">
                <div class="row" style="justify-content: space-between">
                    <div class="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="align-items: center;">
                        <div style="cursor:pointer; padding-top: 8px;" @click="goBack()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#52526C" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </div>
                        <div class="ps-4" style="font-size: 1.2rem; font-weight: 500;">Approval</div>
                    </div>
                    
                    <div class="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div class="d-flex button-position-approve" style="flex-wrap: wrap">
                            <div class="pe-2 container-button-action">
                                <button @click="saveLandApproval(this.CurrentListing.land_id)" class="btn btn-primary btn-block btn-size-approve p-2">Approve</button>
                            </div>
                            <div class="container-button-action">
                                <button @click="openFeedback(this.CurrentListing.land_id)" data-bs-toggle="modal" data-bs-target="#rejectBox" class="btn btn-danger btn-block btn-size-approve p-2">Reject</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-2 card">
                <h4 class="p-3 m-0">{{ this.CurrentListing.land_title }}</h4>

                <!-- image -->
                <div class="p-3 d-flex">
                    <div class="d-flex" style="cursor:pointer; height: 300px; width: 300vh; overflow: scroll;">
                        <div class="d-flex" v-for="(item, index) in this.CurrentListing.images.slice(0, 12)" :key="index">
                            <div style="width: 500px; padding-left: 10px; cursor: pointer" onclick="document.getElementById('popupToggle').click()">
                                <img class="p-0 img-fluid for-light small-imgs" style="height: 100%; width: 100%; border-radius: 10px;" :src="item.image" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- details -->
                <div class="p-3 row">
                    <div class="px-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <!-- LEFT SIDE (HOUSE OVERVIEW)-->
                        <div class="bg-white p-3 rounded">
                            <div class="d-flex" style="justify-content: space-between;">
                                <h5>Overview</h5>
                                <div><span style="font-weight:500">Land-ID-{{ this.CurrentListing.id }}</span></div>
                            </div>
                            <div class="py-1"></div>
                            <div style="border-bottom: 1px solid #CCCCCC"></div>
                            <div class="row">
                                <div class="border-tab-line1 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                                style="justify-content: center; align-items: center; flex-direction: column">
                                    <div style="text-align: center;">
                                        <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(this.CurrentListing.land_zoning) }}</h4>
                                        <div style="font-size:0.7rem;">Land Zoning</div>
                                    </div>
                                </div>
                                <div class="border-tab-line2 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                                style="justify-content: center; align-items: center; flex-direction: column">
                                    <div style="text-align: center;">
                                        <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(convertType(this.CurrentListing.land_type)) }}</h4>
                                        <div style="font-size:0.7rem;">Land Type</div>
                                    </div>
                                </div>
                                <div class="border-tab-line1 border-tab-line3 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                                style="justify-content: center; align-items: center; flex-direction: column">
                                    <div style="text-align: center;">
                                        <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(convertStatus(this.CurrentListing.land_status)) }}</h4>
                                        <div style="font-size:0.7rem;">Land Status</div>
                                    </div>
                                </div>
                                <div class="border-tab-line3 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                                style="justify-content: center; flex-direction: column; align-items: center;">
                                    <div style="text-align: center;">
                                        <h4 style="color:#6C7592; font-size:0.8rem;">{{ this.CurrentListing.size_of_land }}</h4>
                                        <div style="font-size:0.7rem">{{ capitalizeFirstLetter(this.CurrentListing.size_of_land_unit) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-2"></div>

                        <!-- detail -->
                        <div class="bg-white rounded ">
                            <div class="info">
                                <div data-intro="This is the your details" id="info-bar-tour" style=" overflow: hidden;">
                                    <div class="d-flex row" style="align-items: center;">

                                        <div class="p-0 col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="cursor: pointer;">
                                            <div :class="{ 'border-tab': tab1 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('description')">
                                                <h6 class="m-0">Description</h6>
                                            </div>
                                        </div>

                                        <div class="p-0 col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="cursor: pointer;">
                                            <div :class="{ 'border-tab': tab2 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('details')">
                                                <h6 class="m-0">Details</h6>
                                            </div>
                                        </div>

                                        <div class="p-0 col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="cursor: pointer;">
                                            <div :class="{ 'border-tab': tab3 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('location')">
                                                <h6 class="m-0">About Location</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="this.tab1" class="p-3">
                                    <div style="font-size: 0.9rem; padding-bottom:20px" :style="{ whiteSpace: 'pre-line' }">
                                        {{ this.CurrentListing.description }}
                                    </div>
                                </div>
                                
                                <div v-if="this.tab2" class="p-3">
                                    <h6>Topography:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.land_topography ? capitalizeFirstLetter(this.CurrentListing.land_topography) : 'Not Available' }}
                                    </div>

                                    <h6>Category:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.land_category ? convertCategory(this.CurrentListing.land_category) : 'Not Available' }}
                                    </div>

                                    <h6>Sales Type:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.land_sales_type ? convertSalesType(this.CurrentListing.land_sales_type) : 'Not Available' }}
                                    </div>
                                    
                                    <h6>Price:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        RM {{ parseFloat(this.CurrentListing.land_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                    </div>
                                    
                                    <h6>Price per {{ this.CurrentListing.price_land_size_unit }}:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">RM {{ parseFloat(this.CurrentListing.price_land_size_amount).toFixed(2) }}</div>
                                </div>
                                
                                <div v-if="this.tab3" class="p-3">
                                    <h6>Address:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.property_address ? this.CurrentListing.property_address : 'Not Available' }}
                                    </div>

                                    <h6>Mukim:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.property_mukim ? this.CurrentListing.property_mukim : 'Not Available' }}
                                    </div>
                                    
                                    <h6>District:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.property_district ? this.CurrentListing.property_district : 'Not Available' }}
                                    </div>
                                    
                                    <h6>City:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.city ? this.CurrentListing.city : 'Not Available' }}
                                    </div>
                                    
                                    <h6>Postcode:</h6>
                                    <div style="padding-bottom: 20px; font-size: 0.9rem">
                                        {{ this.CurrentListing.property_postcode ? this.CurrentListing.property_postcode : 'Not Available' }}
                                    </div>
                                    
                                    <h6>State:</h6>
                                    <div style="padding-bottom: 30px; font-size: 0.9rem">
                                        {{ this.CurrentListing.state ? this.CurrentListing.state : 'Not Available' }}
                                    </div>
                                    
                                    <iframe
                                        width="100%"
                                        height="450"
                                        frameborder="0" 
                                        style="border:0"
                                        :src="mapSrc"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>       
                    </div>
                </div>

                <!-- Trigger Button and Hidden Checkbox -->
                <input type="checkbox" id="popupToggle">
                <!-- Popup Structure -->
                <div class="overlay">
                    <div class="popup">
                        <label for="popupToggle" class="close-btn position-close-btn" >
                            <span style="font-weight: 600; color: #FEFEFE;">X</span>
                        </label>
                        <div class="carousel-container">
                            <transition-group name="fade" tag="div">
                                <div v-for="(image, imageIndex) in this.CurrentListing.images" :key="imageIndex" style="width: 100%; height:100%">
                                    <img 
                                        :src="image.image" 
                                        v-show="currentIndexes1[0] === imageIndex" 
                                        class="carousel-image" 
                                        style="height: 100%;" 
                                    />
                                </div>
                            </transition-group>

                            <button @click="prev1(0)" class="carousel-control left"><span style="z-index: 1000 !important;">&lt;</span></button>
                            <button @click="next1(0)" class="carousel-control right"><span style="z-index: 1000 !important;">&gt;</span></button>

                            <div class="carousel-indicators">
                                <span
                                    v-for="(image, imageIndex) in this.CurrentListing.images"
                                    :key="imageIndex"
                                    :class="{ active: currentIndexes1[0] === imageIndex }"
                                    @click="setIndex1(0, imageIndex)"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- pop up rejection submition -->
    <div class="modal fade modal-bookmark" id="rejectBox" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Feedback</h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div style="overflow: scroll; overflow-x: hidden;" class="p-4">
                    <textarea class="form-control" type="text" placeholder="Message..." v-model="this.reject_message" rows="5"></textarea>
                </div>
                <div class="row p-3">
                    <div class="col-12 d-flex" style="justify-content: flex-end">
                        <div>
                            <button @click="rejectLand()" data-bs-dismiss="modal" class="btn btn-danger btn-block" type="button" style="width: 100%;">
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {
    getLandListing,
    landApproval,
    landReject,
    listPropertyDetails,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Setting Approval",

    components: {},

    data() {
        return {
            // main page
            pageView: 'All',
            landListing: [],
            currentIndexes: [],
            approval: 0,
            searchListing: null,
            landListingLoader: true,
            
            // page view
            CurrentListing: {
                images: []
            },
            currentIndexes1: [0],
            tab1: true,
            tab2: false,
            tab3: false,
            activeTab: "dashboard",

            // reject listing
            reject_landid: null,
            reject_message: null,
        };
    },

    computed: {
        mapSrc() {
            return `https://www.google.com/maps?q=${ this.CurrentListing.land_geolocation }&hl=es;z=14&output=embed`;
        },
    },
    
    beforeMount() {
        this.loadListingLand();
    },
    
    mounted() {},

    created() {},

    methods: {
        // function
        // -------------------------------------------------------------
        // main
        viewLandApproval(id) {
            this.pageView = 'detail';
            this.PropertyListDetail(id);
        },

        performSearch() {
            this.loadListingLand();
        },
        
        convertTime(dateStr) {
            const date = new Date(dateStr);
            const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
            return formattedDate;
        },

        prev(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] - 1 + totalImages) % totalImages;
        },
        next(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] + 1) % totalImages;
        },
        setIndex(landIndex, imageIndex) {
            this.currentIndexes[landIndex] = imageIndex;
        },

        // reject land
        openFeedback(id) {
            this.reject_landid = id;
            this.reject_message = null;
        },

        // land listing details function
        goBack() {
            this.pageView = 'All';
        },
        prev1(landIndex) {
            const totalImages = this.CurrentListing.images.length;
            this.currentIndexes1[landIndex] = (this.currentIndexes1[landIndex] - 1 + totalImages) % totalImages;
        },
        next1(landIndex) {
            const totalImages = this.CurrentListing.images.length;
            this.currentIndexes1[landIndex] = (this.currentIndexes1[landIndex] + 1) % totalImages;
        },
        setIndex1(landIndex, imageIndex) {
            this.currentIndexes1[landIndex] = imageIndex;
        },
        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },
        convertType(text) {
            const arr = {
                "sale": "For Sale",
                "rent": "For Rent",
                "lease": "For Lease"
            };
            return arr[text] || text;
        },
        convertStatus(text) {
            const arr = {
                "freehold_open": "Freehold Open",
                "freehold_mreserved": "Freehold Malay Reserved / MCL",
                "freehold_clnt": "CL / NT (Sabah/Sarawak)",
                "leasehold_open": "Leasehold Open",
                "leasehold_mreserved": "Leasehold Malay Reserved / MCL",
                "easehold_clnt": "Leasehold CL / NT (Sabah/Sarawak)",
            };
            return arr[text] || text;
        },
        convertCategory(text) {
            const arr = {
                "vacant_land" : "Vacant Land",
                "land_bungalow" : "Land + Bungalow / Semi D Land",
                "land_agriculture" : "Land + Agriculture status",
                "land_building" : "Land + Building status",
                "land_industry" : "Land + Industry status",
                "land_commercial" : "Land + Commercial status",
                "land_plantation" : "Land + Plantation / Orchard",
                "land_house" : "Land + House",
                "land_shoplot" : "Land + Shoplot / Supermarket",
                "land_petrol" : "Land + Petrol Station",
                "land_hotel" : "Land + Building / Hotel / Resort / Villa",
                "land_warehouse" : "Land + Warehouse / Factory",
                "land_do" : "Land + DO / KM",
                "land_mining" : "Land + Mining / Sand / Quary",
                "land_island" : "Land + Island",
                "land_other" : "(All) Land + Other / Mix",
            };
            return arr[text] || text;
        },
        convertSalesType(text) {
            const arr = {
                "sold": "Subsale",
                "new": "New Project",
            };
            return arr[text] || text;
        },
        setActiveTab(cat) {
            this.activeTab =  cat;

            if (this.activeTab == 'description') {
                this.tab1 = true;
                this.tab2 = false;
                this.tab3 = false;
            } else if (this.activeTab == 'details') {
                this.tab1 = false;
                this.tab2 = true;
                this.tab3 = false;
            } else if (this.activeTab == 'location') {
                this.tab1 = false;
                this.tab2 = false;
                this.tab3 = true;
            }
        },




        // ajax
        // -------------------------------------------------------------
        async loadListingLand() {
            this.landListingLoader = true;

            const dataPass = {
                search: this.searchListing,
                city: null,
                state: null,
                area: null,
                price: null,
                corporate: "0",
                approval: "0",
                check_approval: "yes",
            };
            try {
                await getLandListing(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.landListing = response.data.data;
                        this.currentIndexes = this.landListing.map(() => 0);
                        this.landListingLoader = false;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
         
        async saveLandApproval(landId) {
            const dataPass = {
                landId: landId
            };

            try {
                await landApproval(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("Success");
                        this.loadListingLand();
                        this.pageView = 'All';
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async rejectLand() {
            const dataPass = {
                landId: this.reject_landid,
                message: this.reject_message,
            };

            try {
                await landReject(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("Success");
                        this.loadListingLand();
                        this.pageView = 'All';
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async PropertyListDetail(id) {
            this.CurrentListing = {
                images: []
            };

            const dataPass = {
                id_listing: id
            };

            try {
                await listPropertyDetails(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.CurrentListing = response.data.data;
                        this.CurrentListing.land_id = id;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },




        // uiux
        // -------------------------------------------------------------
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
    },
};
</script>