
<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 40%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-type {
  background:#191E32;
  color:#FEFEFE;
  width:100%;
}


.carousel-container {
position: relative;
width: 100%;
}

.carousel-image {
width: 100%;
object-fit: cover;
}

.carousel-control {
position: absolute;
top: 50%;
transform: translateY(-50%);
background-color: rgba(0, 0, 0, 0.5);
color: white;
border: none;
padding: 10px;
cursor: pointer;
}

.carousel-control.left {
left: 10px;
}

.carousel-control.right {
right: 10px;
}

.carousel-indicators {
display: flex;
justify-content: center;
margin-top: 10px;
}

.carousel-indicators span {
display: inline-block;
width: 10px;
height: 10px;
margin: 0 5px;
background-color: grey;
border-radius: 50%;
cursor: pointer;
}

.carousel-indicators .active {
background-color: black;
}

.land-desc-name {
    display: -webkit-box;
    -webkit-line-clamp: 18;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.action-box {
    flex-direction: row;
}
.btn-interested {
    width: 200px;
}
@media (max-width: 1300px) {
    .action-box {
        flex-direction: column;
    }
    .btn-interested {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .action-box {
        flex-direction: column;
    }
    .btn-interested {
        width: 100%;
    }
    .box-corporate-img {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .box-corporate-desc {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">

        <div class="d-flex" style="flex-direction: column;" v-if="this.tableLoader == false">
            <div v-if="this.emptyListing">
                <div class="row">
                    <div class="d-flex pb-3" style="justify-content: space-around; flex-wrap: wrap"
                    v-for="(item, index) in this.landListing" :key="index">

                        <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                            <div class="box-corporate-img card d-flex" style="height: 100%;">
                                <div class="carousel-container">
                                    <transition-group name="fade" tag="div">
                                        <div v-for="(image, imageIndex) in item.images" :key="imageIndex" style="width: 100%;">
                                            <img :src="image.image" v-show="currentIndexes[index] === imageIndex" class="carousel-image" 
                                            style="height: 320px; border-top-left-radius: 10px; border-top-right-radius: 10px" />
                                        </div>
                                    </transition-group>

                                    <!-- Carousel controls (previous/next) -->
                                    <button @click="prev(index)" class="carousel-control left">&lt;</button>
                                    <button @click="next(index)" class="carousel-control right">&gt;</button>

                                    <!-- Carousel indicators -->
                                    <div class="carousel-indicators">
                                        <span
                                            v-for="(image, imageIndex) in item.images"
                                            :key="imageIndex"
                                            :class="{ active: currentIndexes[index] === imageIndex }"
                                            @click="setIndex(index, imageIndex)"
                                        ></span>
                                    </div>
                                </div>

                                <div class="p-3">
                                    <div style="font-size: 1rem; font-weight: 450">{{ item.land_title }}</div>
                                    <div style="font-size: 1.5rem; font-weight: 500">
                                        RM {{ parseFloat(item.land_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="box-corporate-desc card d-flex p-3" style="height: 100%; justify-content: space-between;">
                                <div>
                                    <div class="land-desc-name" style="font-size: 0.8rem; font-weight: 300"  :style="{ whiteSpace: 'pre-line' }">{{ item.description }}</div>
                                </div>
                                <div class="d-flex action-box" style="justify-content: space-between">
                                    <div class="pb-2" style="font-size: 0.8rem; font-weight: 450">{{ item.city }} {{ item.state }}</div>
                                    <div><button data-bs-toggle="modal" data-bs-target="#cooporate" class="btn btn-primary btn-block btn-interested" @click="setTitle(item.land_title)">Im Interested</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                <div>
                    <img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
                </div>
                <div style="text-align: center;">
                    <h6>No result found!</h6>
                </div>
            </div>
        </div>
        <div class="px-4 w-100" v-else>
            <div class="d-flex" style="flex-direction: column; justify-content: center;">
                <h6 class="mb-0 text-center">Please Wait...</h6>
                <div class="loader-box">
                    <div class="loader-3"></div>
                </div>
            </div>
        </div>


        <!-- pop up -->
        <div class="modal fade modal-bookmark" id="cooporate" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">My Inquiry</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;" class="ps-3 pe-3">
                        <div class="modal-body row" style="justify-content: space-between">
                            <div class="py-2">
                                <div style="font-weight: 450">Company</div>
                                <input class="form-control py-2" id="total_sales" type="text" v-model="this.companyName" placeholder="Your Company"/>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">Designation</div>
                                <select class="form-select form-control-primary py-2" name="select" v-model="this.cDesignation" >
                                    <option value="" disabled selected> Your Designation </option>
                                    <option value="tansri">Tan Sri</option>
                                    <option value="datosri">Dato' Sri</option>
                                    <option value="dato">Dato'</option>
                                    <option value="datin">Datin</option>
                                    <option value="encik">Encik</option>
                                    <option value="puan">Puan</option>
                                    <option value="cik">Cik</option>
                                </select>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">Name</div>
                                <input class="form-control py-2" id="total_sales" type="text" v-model="this.cName" placeholder="Your Name"/>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">Email</div>
                                <input class="form-control py-2" id="total_sales" type="text" v-model="this.cEmail" placeholder="Your Email"/>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">Phone Number</div>
                                <input class="form-control" type="text" v-model="this.cPhone" placeholder="Phone"/>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">I Want:</div>
                                
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        More Information
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Set an Appointment
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Set for Viewing
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Pay Booking
                                    </label>
                                </div>
                            </div>
                            <div class="py-2">
                                <div style="font-weight: 450">Tell us about your inquiry</div>
                                <textarea class="form-control" id="putData" rows="4" v-model="this.cDetail" placeholder="Your Message..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button @click="sendRequest()" data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import {
    getLandListing,
    sendCorporateEnquiry
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    components: {
    },

    data() {
        return {
            tableLoader: true,
            emptyListing: true,
            currentIndexes: [],
            selectedTab: 'property',
            typeSearch: 'Property',
            Listing: [],
            landListing: [],
            listSearch: null,
            listCity: null,
            listState: "",
            listArea: null,
            listPrice: null,
            listOther: "",
            listCategory: "",
            listStatus: "",
            listType: "",
            listSize: "hectar",
            redirect: true,

            companyName: null,
            cDesignation: "",
            cName: null,
            cEmail: null,
            cPhone: null,
            cDetail: null,
            titlePosting: null,
            sentDesignation: "",
            corporate: "1",
            user_id_listing: null,
        };
    },

    computed: {
    },

    beforeMount() {
    },

    mounted() {
        this.LandListing();
    },

    created() {
    },

    methods: {
        prev(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] - 1 + totalImages) % totalImages;
        },

        next(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] + 1) % totalImages;
        },

        setIndex(landIndex, imageIndex) {
            this.currentIndexes[landIndex] = imageIndex;
        },

        async LandListing() {
            this.tableLoader = true;

            const dataPass = {
                search: this.listSearch,
                city: this.listCity,
                state: this.listState,
                area: this.listArea,
                price: this.listPrice,
                corporate: this.corporate,
                user_id_listing: null,
                sold: "0",
                check_approval: true,
            };

            try {
                await getLandListing(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.landListing = response.data.data;
                        this.Listing = this.landListing;
                        this.currentIndexes = this.landListing.map(() => 0);
                        this.tableLoader = false;
                        this.emptyListing = this.landListing.length ==0? false : true;

                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        setTitle(title) {
            this.titlePosting = title;
        },

        clearfield() {
            this.companyName = null;
            this.cDesignation = "";
            this.cName = null;
            this.cEmail = null;
            this.cPhone = null;
            this.cDetail = null;
            this.sentDesignation = null;
            this.titlePosting = null;
        },

        async sendRequest() {
            try {
                if (this.cDesignation == 'tansri') {
                    this.sentDesignation = "Tan Sri";
                } else if (this.cDesignation == 'datosri') {
                    this.sentDesignation = "Dato' Sri";
                } else if (this.cDesignation == 'dato') {
                    this.sentDesignation = "Dato'";
                } else if (this.cDesignation == 'datin') {
                    this.sentDesignation = "Datin";
                } else if (this.cDesignation == 'encik') {
                    this.sentDesignation = "Encik";
                } else if (this.cDesignation == 'puan') {
                    this.sentDesignation = "Puan";
                } else if (this.cDesignation == 'cik') {
                    this.sentDesignation = "Cik";
                }
                
                const dataPass = {
                    company : this.companyName,
                    designation : this.sentDesignation,
                    name : this.cName,
                    email : this.cEmail,
                    phone : this.cPhone,
                    detail : this.cDetail,
                    posting : this.titlePosting,
                };
                await sendCorporateEnquiry(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Successfully sent your message");
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
            this.clearfield();
        },




        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        redirectDetails(id) {
            localStorage.setItem("type_property", this.typeSearch);
            localStorage.setItem("listing_id", id);
            this.$router.push("/web/property-details");
        }
    },
};
</script>
