<template>
    <div class="page-wrapper compact-wrapper" id="pageWrapper">
        <div class="container-fluid p-0">
            <div class="comingsoon auth-bg-video">
                <video class="bgvideo-comingsoon" id="bgvid" poster="@/assets/images/other-images/coming-soon-bg.jpg" playsinline="" autoplay="" muted="" loop="">
                    <source src="@/assets/images/comingsoon.mp4" type="video/mp4">
                </video>
                <div class="comingsoon-inner text-center d-flex" style="flex-direction: column; align-items: center">
                    <div class="card rounded d-flex" style="background-color: #191E32; width: 320px;align-items: center;">
                        <img src="@/assets/images/logo/logo-header.png" style="width: 250px;" alt="logo">
                        <h6 class="px-4" style="color: #FEFEFE;">Get ahead of the game! Be the first to join, subscribe now!</h6>
                        <div class="d-flex pb-4">
                            <input v-model="this.emailAddress" class="form-control" type="text" placeholder="Your Email" required style="width: 150px; border-top-right-radius: 0px; border-bottom-right-radius: 0px;" />
                            <button @click="savePackage()" class="btn btn-color" type="button" style="width: 130px;  border-top-left-radius: 0px; border-bottom-left-radius: 0px;color: #fefefe; background-color: #C89835 !important; border-color: #C89835 !important;">
                                Subscribe
                            </button>
                        </div>

                    </div>
                    <h5>WE ARE COMING SOON</h5>
                    <div class="countdown" id="clockdiv">
                        <!-- <Timer /> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    saveEmail,
} from "@/helper/apiservice/axios-http";
import Timer from './comingsoon_timer';

export default {
    components: {
        Timer
    },
    data() {
        return {
            emailAddress: null,
        };
    },
    method: {
        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },

        async savePackage(id) {
            const dataPass = {
                email: this.emailAddress,
            };

            try {
                await saveEmail(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.emailAddress = null;

                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    }
};
</script>
