import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import WebsiteBody from "../components/webBody";
////
////
////
////
//// website
import homepage from "../pages/website/homepage.vue";
import countDown from "../pages/comingsoon/comingsoon_video";
import search from "../pages/website/search.vue";
import propertyListing from "../pages/website/propertyListing.vue";
import contactus from "../pages/website/contactus.vue";
import aboutus from "../pages/website/aboutus.vue";
import listofagent from "../pages/website/listofagent.vue";
import corporate from "../pages/website/corporate.vue";
import joinus from "../pages/website/joinus.vue";
import termncondition from "../pages/website/termCondition.vue";
import privacypolicy from "../pages/website/privacyPolicy.vue";
////
////
////
////
//// blog
import blog from "../pages/website/blog/index.vue";
import blog1 from "../pages/website/blog/blog1.vue";
import blog2 from "../pages/website/blog/blog2.vue";
import blog3 from "../pages/website/blog/blog3.vue";
import blog4 from "../pages/website/blog/blog4.vue";
import blog5 from "../pages/website/blog/blog5.vue";
import blog6 from "../pages/website/blog/blog6.vue";
import blog7 from "../pages/website/blog/blog7.vue";
////
////
////
////
//// auth
import login from "../auth/login";
import resetPassword from "../auth/passwordReset.vue";
import verifyEmail from "../auth/emailVerify.vue";
////
////
////
////
//// dashboard
import stats from "../pages/home/home.vue";
////
////
////
////
//// setting
import coins from "../pages/setting/coins.vue";
import packages from "../pages/setting/packages.vue";
import coupons from "../pages/setting/coupons.vue";
import listing from "../pages/setting/listing.vue";
import corporateListing from "../pages/setting/corporateListing.vue";
import token from "../pages/setting/token.vue";
import company from "../pages/setting/company.vue";
import approval from "../pages/setting/approval.vue";
import registration from "../pages/setting/registration.vue";
import userManagement from "../pages/setting/userManagement.vue";
////
////
////
////
//// reporting
import subscribe from "../pages/report/subscribe.vue";
import rlisting from "../pages/report/listing.vue";
import metric from "../pages/report/metric.vue";
////
////
////
////
////
import userProfile from "../pages/users/profile/userProfile.vue";
import userBalance from "../pages/users/profile/balance.vue";
import userSubscription from "../pages/users/profile/subscription.vue";
import userInvoiceSub from "../pages/users/profile/invoice.vue";
import userOwnPage from "../pages/users/profile/userOwnPage.vue";
////
////
////
////
////
//// error page
import Error404 from "../pages/error/error404";
////
////
////
import PaymentFailed from '../pages/failed.vue';
import PaymentSuccess from '../pages/success.vue';
////
////
//// axios call
import { findUserDetails } from "@/helper/apiservice/axios-http";

const blogPosts = [
    { path: "", component: blog, title: "Blog" },
    { path: "Perspiciatis-unde-omnis1", component: blog1, title: "Perspiciatis unde omnis1" },
    { path: "Perspiciatis-unde-omnis2", component: blog2, title: "Perspiciatis unde omnis2" },
    { path: "Perspiciatis-unde-omnis3", component: blog3, title: "Perspiciatis unde omnis3" },
    { path: "Perspiciatis-unde-omnis4", component: blog4, title: "Perspiciatis unde omnis4" },
    { path: "Perspiciatis-unde-omnis5", component: blog5, title: "Perspiciatis unde omnis5" },
    { path: "Perspiciatis-unde-omnis6", component: blog6, title: "Perspiciatis unde omnis6" },
    { path: "Perspiciatis-unde-omnis7", component: blog7, title: "Perspiciatis unde omnis7" },
];

const routes = [
    {
        path: '/payment-failed/',
        name: 'PaymentFailed',
        component: PaymentFailed,
        props: true
      },
      {
        path: '/payment-success/',
        name: 'PaymentSuccess',
        component: PaymentSuccess,
        props: true
      },
    {
        path: "/auth",
        children: [
            {
                path: 'countDown',
                name: 'countDown',
                component: countDown,
                meta: {
                    title: 'Landcartel Coming Soon',
                }
            },
            {
                path: "login",
                name: "login",
                component: login,
                meta: {
                    title: "Login",
                },
            },
            {
                path: "resetPassword",
                name: "resetPassword",
                component: resetPassword,
                meta: {
                    title: "Reset Password",
                },
            },
            {
                path: "verifyEmail",
                name: "verifyEmail",
                component: verifyEmail,
                meta: {
                    title: "Email Verified",
                },
            },
        ],
    },
    {
        path: "/dashboard",
        component: Body,
        children: [
            {
                path: "stats",
                name: "stats",
                component: stats,
                meta: {
                    title: "Dashboard",
                },
            },
        ],
    },
    {
        path: "/web",
        component: WebsiteBody,
        children: [
            {
                path: "blog",
                name: "blog",
                children: blogPosts.map(post => ({
                    path: post.path,
                    component: post.component,
                    meta: { title: post.title },
                })),
            },
            {
                path: "homepage",
                name: "homepage",
                component: homepage,
                meta: {
                    title: "Homepage",
                },
            },
            {
                path: "aboutus",
                name: "aboutus",
                component: aboutus,
                meta: {
                    title: "About Us",
                },
            },
            {
                path: "contactus",
                name: "contactus",
                component: contactus,
                meta: {
                    title: "Contact Us",
                },
            },
            {
                path: "search",
                name: "search",
                component: search,
                meta: {
                    title: "Search Listing",
                },
            },
            {
                path: "property-details",
                name: "property-details",
                component: propertyListing,
                meta: {
                    title: "Listing Details",
                },
            },
            {
                path: "list-of-agent",
                name: "list-of-agent",
                component: listofagent,
                meta: {
                    title: "Find Agent",
                },
            },
            {
                path: "corporate",
                name: "corporate",
                component: corporate,
                meta: {
                    title: "Corporate Listing",
                },
            },
            {
                path: "joinus",
                name: "joinus",
                component: joinus,
                meta: {
                    title: "Join Us",
                },
            },
            {
                path: "termncondition",
                name: "termncondition",
                component: termncondition,
                meta: {
                    title: "Our Term & Condition",
                },
            },
            {
                path: "privacypolicy",
                name: "privacypolicy",
                component: privacypolicy,
                meta: {
                    title: "Our Privacy Policy",
                },
            },
        ],
    },
    {
        path: "/setting",
        component: Body,
        children: [
            {
                path: "coins",
                name: "coins",
                component: coins,
                meta: {
                    title: "Setting Exchange Rates",
                },
            },
            {
                path: "packages",
                name: "packages",
                component: packages,
                meta: {
                    title: "Setting Packages",
                },
            },
            {
                path: "coupons",
                name: "coupons",
                component: coupons,
                meta: {
                    title: "Setting Coupons",
                },
            },
            {
                path: "listing",
                name: "listing",
                component: listing,
                meta: {
                    title: "My Listing",
                },
            },
            {
                path: "corporate-listing",
                name: "corporate-listing",
                component: corporateListing,
                meta: {
                    title: "Setting Corporate Listing",
                },
            },
            {
                path: "token",
                name: "token",
                component: token,
                meta: {
                    title: "Setting Token",
                },
            },
            {
                path: "company",
                name: "company",
                component: company,
                meta: {
                    title: "Setting Company",
                },
            },
            {
                path: "approval",
                name: "approval",
                component: approval,
                meta: {
                    title: "Approval Listing",
                },
            },
            {
                path: "registration",
                name: "registration",
                component: registration,
                meta: {
                    title: "Register User",
                },
            },
            {
                path: "usermanagement",
                name: "usermanagement",
                component: userManagement,
                meta: {
                    title: "Manage User",
                },
            },
        ],
    },
    {
        path: "/report",
        component: Body,
        children: [
            {
                path: "subscribe",
                name: "subscribe",
                component: subscribe,
                meta: {
                    title: "User Subscribe",
                },
            },
            {
                path: "rlisting",
                name: "rlisting",
                component: rlisting,
                meta: {
                    title: "Listing Performance",
                },
            },
            {
                path: "metric",
                name: "metric",
                component: metric,
                meta: {
                    title: "Metric Performance",
                },
            },
        ],
    },
    {
        path: "/user",
        component: Body,
        children: [
            {
                path: "profile",
                name: "profile",
                component: userProfile,
                meta: {
                    title: "My Profile",
                },
            },
            {
                path: "balance",
                name: "balance",
                component: userBalance,
                meta: {
                    title: "My Balance",
                },
            },
            {
                path: "subscription",
                name: "subscription",
                component: userSubscription,
                meta: {
                    title: "My Subscription",
                },
            },
            {
                path: "invoicesub",
                name: "invoicesub",
                component: userInvoiceSub,
                meta: {
                    title: "Invoice",
                },
            },
        ],
    },
    {
        path: "/error",
        children: [
            {
                path: "404",
                name: "404",
                component: Error404,
                meta: {
                    title: "Not Found",
                },
            },
        ],
    },
    {
        path: "/:username",
        component: WebsiteBody,
        name: "customUserPage",
        meta: {
            title: "User Landing Page",
        },
        children: [
            {
                path: "",
                name: "userLanding",
                component: userOwnPage,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    // Page that does not require token_credential
    const path1 = ["/auth/login", "/auth/forget-password"];
    const path2 = ["/"];
    const path4 = [
        "/web/homepage", 
        "/web/search", 
        "/web/list-of-agent",
        "/web/aboutus",
        "/web/contactus",
        "/web/property-details"
    ];

    const now = new Date();
    const nowTime =
        now.getFullYear() +
        "-" +
        String(now.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(now.getDate()).padStart(2, "0") +
        " " +
        String(now.getHours()).padStart(2, "0") +
        ":" +
        String(now.getMinutes()).padStart(2, "0") +
        ":" +
        String(now.getSeconds()).padStart(2, "0");
    // console.log(path1.includes(to.path));
    // console.log(path2.includes(to.path));
    // console.log(path4.includes(to.path));
    if (
        (path1.includes(to.path) 
        || path2.includes(to.path)) 
        &&
        localStorage.getItem("token_credential") &&
        localStorage.getItem("token_expired") >= nowTime
    ) {
        return next("/web/homepage");
        // return next("/web/homepage");
    } else if (
        path2.includes(to.path) &&
        !path4.includes(to.path) &&
        localStorage.getItem("token_expired") <= nowTime
    ) {
        localStorage.removeItem("token_expired");
        localStorage.removeItem("token_credential");
        return next("/web/homepage");
        // return next("/web/homepage");
    } else if (
        path2.includes(to.path) &&
        !path4.includes(to.path) &&
        (!localStorage.getItem("token_credential") ||
            localStorage.getItem("token_credential") === undefined) &&
        (!localStorage.getItem("token_expired") ||
            localStorage.getItem("token_expired") === undefined)
    ) {
        return next("/web/homepage");
        // return next("/web/homepage");
    } else if (to.path) {
        function collectPaths(routes) {
            let paths = [];
            routes.forEach((route) => {
                if (route.children) {
                    route.children
                        .map((child) => child.path)
                        .forEach(function (path) {
                            paths.push(route.path.concat("/").concat(path));
                        });
                }
            });
            return paths;
        }

        const allowedPaths = collectPaths(routes);

        if (!allowedPaths.includes(to.path)) {
            if (to.params.username) {
                try {
                    await findUserDetails({ userName: to.params.username }).then((response) => {
                        if (response.data.success == true) {
                            next();
                            
                        } else {
                            next("/error/404");
                        }
                    })
                } catch (error) {
                    next("/error/404");
                }
            } else {
                next();
                // next("/error/404");
            }
        } else {
            next();
        }
    }
});

export default router;
