<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img height="500" class="img-fluid for-light" src="../assets/images/logo/logo_login.png" alt="loginpage" style="width:40%;"/>
                                    <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="loginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <div>
                                        <h4>Success Email Verification</h4>
                                        <p>You can now access system using same email.</p>
                                        <div class="form-group mb-0">
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="button" @click="redirect1()">Back to sign in</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userAdd, login } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "login",

    data() {
        return {
            showPassword: false,
            result: {
                email: "",
                password: "",
            },
            user: {
                remember: {
                    value: false,
                    errormsg: "",
                },
                email: {
                    value: "",
                    errormsg: "",
                },
                password: {
                    value: "",
                    errormsg: "",
                },
            },
            newName: null,
            newPhone: null,
            newEmail: null,
            register: false,
            newUsername: null,
            newPassword: null,
            newConfirmPassword: null,
            newRole: "3",
        };
    },

    created() {},

    computed: {
        passwordFieldType() {
            return this.showPassword ? "text" : "password";
        },
        showPasswordText() {
            return this.showPassword ? "Hide" : "Show";
        },
    },

    methods: {
        redirect1() {
            this.$router.push("/web/homepage");
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },

        validEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        async loginSubmit() {
            if (
                !this.user.password.value ||
                this.user.password.value.length < 7
            ) {
                this.user.password.errormsg = "min length 7";
            } else {
                this.user.password.errormsg = "";
            }

            if (!this.user.email.value) {
                this.user.email.errormsg = "Invalid email address";
            } else if (!this.validEmail(this.user.email.value)) {
                this.user.email.errormsg = "Valid email required.";
            } else {
                this.user.email.errormsg = "";
            }

            if (!this.user.email.errormsg && !this.user.password.errormsg) {
                const dataPass = {
                    email: this.user.email.value,
                    password: this.user.password.value,
                    remember: this.user.remember.value,
                };

                try {
                    await login(dataPass).then((response) => {
                        if (response.data.success == true) {
                            // STORE USER CREDENTIALS GLOBALLY IN LOCAL STORAGE
                            localStorage.setItem( "token_credential", response.data.data.token );
                            localStorage.setItem( "token_expired",  response.data.data.expired );
                            localStorage.setItem("details", JSON.stringify(response.data.data.details));
                            localStorage.setItem("user_id", response.data.data.id);
                            localStorage.setItem("role", response.data.data.email);
                            localStorage.setItem("username", response.data.data.detail.username);
                            localStorage.setItem("image", response.data.data.detail.image);
                            localStorage.setItem("access", response.data.data.role);
                            this.$store.dispatch("menu/refreshMenuItems");
                            this.$router.push("/dashboard/stats");
                        } else {
                            // this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    // this.notificationError("Something went wrong!");
                }
            }
        },

        openRegisterTab(state) {
            this.register = state;
        },

        async registerUser() {
            try {
                const dataPass = {
                    name : this.newName,
                    email : this.newEmail,
                    phone : this.newPhone,
                    username : this.newUsername,
                    password : this.newPassword,
                    cpassword : this.newConfirmPassword,
                    role : this.newRole,
                };
                await userAdd(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Success");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        
                    } else {
                        // this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                // this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
