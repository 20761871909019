<style scoped>
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="p-4 d-flex" style="justify-content: space-between; font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Company Info</div>
                        <div>
                            <button
                            @click="saveCompanyInfo()"
                            class="btn btn-color"
                            type="button"
                            style="
                                width: 100%;
                                color: #fefefe;
                                background-color: #04318c !important;
                                border-color: #04318c !important;
                            "
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div style="padding-left: 30px; padding-right: 30px; display: flex; flex-direction: column;">
                        <div class="mt-4" style="font-weight: 400;">Company Name:</div>
                        <div><input v-model="this.company_name" class="form-control" type="text" placeholder="Company Name" /></div>
                        
                        <div class="mt-4" style="font-weight: 400;">Company ROC:</div>
                        <div><input v-model="this.company_roc" class="form-control" type="text" placeholder="Company ROC" /></div>
                        
                        <div class="mt-4" style="font-weight: 400;">Company Address:</div>
                        <div>
                            <textarea class="form-control" id="putData" rows="5" v-model="this.company_address" placeholder="Company Addres"></textarea>
                        </div>

                        <div class="mt-4" style="font-weight: 400;">Company Phone Number:</div>
                        <div><input v-model="this.company_phone" class="form-control" type="text" placeholder="Company Phone" /></div>

                        <div class="mt-4" style="font-weight: 400;">Company Email:</div>
                        <div><input v-model="this.company_email" class="form-control" type="text" placeholder="Company Email" /></div>
                    </div>

                    <div class="mt-4"></div>

                    <div class="p-4 d-flex" style="font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Social Media</div>
                    </div>

                    <div style="padding-left: 30px; padding-right: 30px; display: flex; flex-direction: column;">
                        <div class="mt-4" style="font-weight: 400;">Instagram:</div>
                        <div>
                            <label class="sr-only" for="inlineFormInputGroup">https://www.instagram.com/</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" style="height: 38px; font-size: 0.9rem; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">https://www.instagram.com/</div>
                                </div>
                                <input v-model="this.instagram" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Instagram Username">
                            </div>
                        </div>

                        <div class="mt-4" style="font-weight: 400;">Facebook:</div>
                        <div>
                            <label class="sr-only" for="inlineFormInputGroup">https://www.facebook.com/</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" style="height: 38px; font-size: 0.9rem; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">https://www.facebook.com/</div>
                                </div>
                                <input v-model="this.facebook" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Facebook Username">
                            </div>
                        </div>

                        <div class="mt-4" style="font-weight: 400;">Tiktok:</div>
                        <div>
                            <label class="sr-only" for="inlineFormInputGroup">https://www.tiktok.com/@</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" style="height: 38px; font-size: 0.9rem; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">https://www.tiktok.com/@</div>
                                </div>
                                <input v-model="this.tiktok" type="text" class="form-control" id="inlineFormInputGroup" placeholder="TikTok Username">
                            </div>
                        </div>

                        <div class="mt-4" style="font-weight: 400;">Whatsapp:</div>
                        <div><input v-model="this.whatsapp" class="form-control" type="text" placeholder="Phone Number" /></div>
                    </div>

                    <div class="mt-4"></div>
                    
                    <div class="p-4 d-flex" style="font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Auto Approval Period</div>
                    </div>

                    <div style="padding-left: 30px; padding-right: 30px; display: flex; flex-direction: column;">
                        <div class="mt-4" style="font-weight: 400;">Period of Time:</div>
                        <div>
                            <label class="sr-only" for="inlineFormInputGroup">Hour (s)</label>
                            <div class="input-group mb-2">
                                <input v-model="this.periodTime" type="text" class="form-control" id="inlineFormInputGroup" placeholder="24">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Hour (s)</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4"></div>

                    <div class="p-4 d-flex" style="font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Term of use</div>
                    </div>
                    <div style="padding-left: 30px; padding-right: 30px; display: flex; flex-direction: column;">
                        <div class="mt-4">
                            <textarea class="form-control" id="putData" rows="20" v-model="this.tnc" placeholder="Term of use detail..."></textarea>
                        </div>
                    </div>

                    <div class="mt-4"></div>

                    <div class="p-4 d-flex" style="font-size: 1.2rem; font-weight: 500; align-items: center; border-bottom: 1px solid #cccccc" >
                        <div class="ps-2">Privacy Policy</div>
                    </div>
                    <div style="padding-left: 30px; padding-right: 30px; padding-bottom: 40px; display: flex; flex-direction: column;">
                        <div class="mt-4">
                            <textarea class="form-control" id="putData" rows="20" v-model="this.privacy" placeholder="Privacy policy detail..."></textarea>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getInfoCompany,
    companyInfoSave,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Exchange Rates",

    data() {
        return {
            instagram: null,
            whatsapp: null,
            facebook: null,
            tiktok: null,
            tnc: null,
            periodTime: null,
            privacy: null,
            company_name: null,
            company_roc: null,
            company_address: null,
            company_phone: null,
            company_email: null
        }
    },

    beforeMount() {
        this.loadCompanyInfo();

    },
    
    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },

        async loadCompanyInfo() {
            try {
                await getInfoCompany().then((response) => {
                    if (response.data.success === true) {
                        
                        this.company_name = response.data.data.name;
                        this.company_phone = response.data.data.phone;
                        this.instagram = response.data.data.instagram;
                        this.facebook = response.data.data.facebook;
                        this.whatsapp = response.data.data.whatsapp;
                        this.tiktok = response.data.data.tiktok;
                        this.company_roc = response.data.data.roc;
                        this.company_address = response.data.data.address;
                        this.company_email = response.data.data.email;
                        this.tnc = response.data.data.tnc;
                        this.privacy = response.data.data.privacy;
                        this.periodTime = response.data.data.period_time;
                        
                    } 
                    // else {
                    //     this.notificationError(response.data.message);
                    // }
                });
            } catch (error) {
                // this.notificationError("Something went wrong!");
            }
        },

        async saveCompanyInfo() {
            const dataPass = {
                company_name: this.company_name,
                company_phone: this.company_phone,
                instagram: this.instagram,
                facebook: this.facebook,
                whatsapp: this.whatsapp,
                tiktok: this.tiktok,
                company_roc: this.company_roc,
                company_address: this.company_address,
                company_email: this.company_email,
                tnc: this.tnc,
                privacy: this.privacy,
                period_time: this.periodTime,
            };

            try {
                await companyInfoSave(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("success");
                        this.loadCompanyInfo();
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }

        },
    }
}
</script>
