<template>
  <section class="flex flex-col items-center justify-center min-h-screen text-center">
    <div style="font-size: 60px;color:forestgreen;">
      <span class="material-symbols-outlined">Thank you for your payment</span>
    </div>
    <h3 class="mt-3 text-xl font-semibold">Your payment was successful. Thank you for your purchase!</h3>
    <p class="text-lg mt-2">
      Here is your order reference no - 
      <span class="text-blue-600 font-bold">{{ saleReference }}</span>
    </p>
    
  </section>
</template>

<script>
export default {
  data() {
    return {
      saleReference: this.$route.query.sale_reference || 'N/A'
    };
  },
  methods: {
    redirectToHomepage() {
      this.$router.push("/user/balance");
    }
  },
  mounted() {
    // Redirect automatically after 5 seconds
    setTimeout(() => {
      this.redirectToHomepage();
    }, 5000); // 5000ms = 5 seconds
  }
};
</script>

<style scoped>
section {
  background-color: #fff;
  padding: 2rem;
}
</style>
