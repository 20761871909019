<style scoped>
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>
<template> 
    <div class="padding-container">
        <!-- ENQUIRY FORM -->
        <div class="row">
            <div class="p-4 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                <h1>Planning to sell/rent or have any question about land?</h1>
                <h6 class="pt-2">Get a free consultation</h6>
                <div>
                    At Landcartel, we're driven by a powerful combination: two decades of experience in system and software development across a wide range of industries, and a deep understanding of the unique needs of both multinational corporations (MNCs) and government-linked companies (GLCs). We've built a strong reputation managing complex technology projects for some of the world's biggest brands. We're passionate about empowering small and medium-sized enterprises (SMEs) with targeted solutions that can help them grow and thrive. Swift is your trusted partner for navigating the ever-evolving world of technology
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
                <div class="p-4">
                    <div class="bg-white p-3 rounded row">
                        <div class="py-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Name</div>
                            <input class="form-control" type="text" v-model="this.name" placeholder="Name"/>
                        </div>
                        <div class="py-2 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Email</div>
                            <input class="form-control" type="text" v-model="this.email" placeholder="Email"/>
                        </div>
                        <div class="py-2 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Phone Number</div>
                            <input class="form-control" type="text" v-model="this.phone" placeholder="Phone"/>
                        </div>
                        <div v-if="this.salesForm" class="py-2 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Inquiry</div>
                            <select class="form-select form-control-primary" name="select" v-model="this.type" >
                                <option value="" disabled selected> Select Inquiry </option>
                                <option value="let">Want to Let</option> 
                                <option value="sell">Want to Sell</option>
                                <option value="buy">Want to Buy</option>
                            </select>
                        </div>
                        <div class="py-2 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem">
                            <div style="font-weight: 450">State</div>
                            <select class="form-select form-control-primary" name="select" v-model="this.state" >
                                <option value="" disabled selected> Select State </option>
                                <option value="PERLIS">PERLIS</option>
                                <option value="KEDAH">KEDAH</option>
                                <option value="PULAU PINANG">PULAU PINANG</option>
                                <option value="PERAK">PERAK</option>
                                <option value="SELANGOR">SELANGOR</option>
                                <option value="WP KUALA LUMPUR">WP KUALA LUMPUR</option>
                                <option value="WP PUTRAJAYA">WP PUTRAJAYA</option>
                                <option value="NEGERI SEMBILAN">NEGERI SEMBILAN</option>
                                <option value="MELAKA">MELAKA</option>
                                <option value="JOHOR">JOHOR</option>
                                <option value="PAHANG">PAHANG</option>
                                <option value="TERENGGANU">TERENGGANU</option>
                                <option value="KELANTAN">KELANTAN</option>
                                <option value="SARAWAK">SARAWAK</option>
                                <option value="SABAH">SABAH</option>
                                <option value="WP LABUAN">WP LABUAN</option>
                            </select>
                        </div>
                        <div class="py-2 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem">
                            <div style="font-weight: 450">City</div>
                            <input v-model="this.city" class="form-control" type="text" placeholder="City"/>
                        </div>
                        <div class="py-2 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Details</div>
                            <textarea class="form-control" id="putData" rows="4" v-model="this.detail" placeholder="Your Message..."></textarea>
                        </div>
                        <div class="py-2 col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                        <div class="">
                            <button @click="sendRequest()" class="btn btn-primary btn-block" style="width: 100%; height: 40px" align="center">Submit Request</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import { sendEnquiry } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    components: {
    },
    data() {
        return {
            name: null,
            email: null,
            phone: null,
            type: "",
            state: "",
            city: null,
            detail: null,
            sentType: "",
            salesForm: true,
        }
    },
    computed: {
    },
    mounted() {
    },
    created() {
        if (this.$route.query.sales) {
            this.salesForm = this.$route.query.sales == 'yes'? false : true;
        }
    },
    methods: {
        //TOAST NOTIFICATION
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },

        clearfield() {
            this.name = null;
            this.email = null;
            this.phone = null;
            this.type = "";
            this.state = "";
            this.city = null;
            this.detail = null;
        },

        async sendRequest() {
            try {
                if (this.type == 'let') {
                    this.sentType = "want to rent land";
                } else if (this.type == 'sell') {
                    this.sentType = "want to sell land";
                } else if (this.type == 'buy') {
                    this.sentType = "want to buy land";
                }
                
                const dataPass = {
                    name : this.name,
                    email : this.email,
                    phone : this.phone,
                    type : this.sentType,
                    state : this.state,
                    city : this.city,
                    remark : this.detail,
                };
                await sendEnquiry(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Successfully sent your message");
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
            this.clearfield();
        }
    },
};
</script>
