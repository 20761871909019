
<style scoped>
.sidebar-wrapper {
  position: relative;
  height: 100%;
}

.sidebar-overlay {
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.83);
  z-index: 1040;
  display: none;
}

.sidebar-overlay.is-active {
  display: block;
}

.sidebar {
  position: fixed;
  top: 76px;
  right: -280px;
  height: 100%;
  width: 280px;
  background-color: #fff;
  z-index: 1050;
  transition: right 0.3s ease;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
}

.sidebar.is-open {
    right: 0;
    transition: right 0.3s ease;
}

.sidebar-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-body {
  padding: 1rem 0;
  overflow-y: auto;
  flex-grow: 1;
}

@media (min-width: 992px) {
  .sidebar {
    left: 0;
  }
  
  .sidebar-overlay {
    display: none !important;
  }
}


.hamburger {
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}

.line {
  display: block;
  width: 20px;
  height: 2px;
  margin: 6px auto;
  background-color: #FEFEFE;
  transition: all 0.3s ease-in-out;
}

.is-active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.is-active .line:nth-child(2) {
  opacity: 0;
}

.is-active .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.pointer{
    cursor: pointer;
}

@media (max-width: 768px) {
    .hamburger-sidebar {
        display: flex !important;
    }
    .navbar-sidebar {
        display: none !important;
    }
}
@media (min-width: 768px) {
    .hamburger-sidebar {
        display: none !important;
    }
    .navbar-sidebar {
        display: flex !important;
    }
}
</style>

<template>
    <div class="d-flex hamburger-sidebar" style="justify-content: flex-end;">
        <button 
            class="navbar-toggler border-0" 
            type="button" 
            @click="toggleMenu"
            :class="{ 'is-active': isOpen }"
            aria-label="Toggle navigation"
        >
        <div class="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            </div>
        </button>

        <div class="sidebar" :class="{ 'is-open': isOpen }" style="background-color: #191E32;">
            <div class="pointer p-4" @click="route1()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Home</div>
            <div class="pointer p-4" @click="route2()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Listing</div>
            <div class="pointer p-4" @click="route4()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Corporate</div>
            <div class="pointer p-4" @click="route3()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Join Us</div>
            <div class="pointer p-4" v-if="this.user.login">
                <button @click="route6()" class="btn btn-primary btn-block" style="width: 100%; height: 40px" align="center">Login</button>
            </div>
            <div v-if="!this.user.login">
                <div class="pointer p-4">
                    <router-link :to="'/user/profile'"><span style="color: #FEFEFE; font-weight: 500; font-size: 1rem !important;">My Profile</span></router-link>
                </div>
                <div class="pointer p-4" v-if="this.user.check">
                    <router-link :to="'/dashboard/stats'"><span style="color: #FEFEFE; font-weight: 500; font-size: 1rem !important;">My Dashboard</span></router-link>
                </div>
                <div class="pointer p-4" v-if="!this.user.check">
                    <router-link :to="'/web/homepage'"><span style="color: #FEFEFE; font-weight: 500; font-size: 1rem !important;">Home</span></router-link>
                </div>
                <div class="pointer p-4">
                    <button @click="userLogout()" class="btn btn-danger btn-block" style="width: 100%; height: 40px" align="center">Log out</button>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex navbar-sidebar" style="justify-content: flex-end; align-items: center;">
        <div class="pointer me-4" @click="route1()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Home</div>
        <div class="pointer me-4" @click="route2()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Listing</div>
        <div class="pointer me-4" @click="route4()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Corporate</div>
        <div class="pointer me-4" @click="route3()" v-if="this.user.check" style="font-weight: 500; font-size: 1rem !important;">Join Us</div>
        <div class="pointer me-4" v-if="this.user.login">
            <button @click="route6()" class="btn btn-primary btn-block" style="width: 100%; height: 40px" align="center">Login</button>
        </div>
        <div class="nav-right pull-right right-header">
            <div class="nav-menus">
                <div class="profile-nav onhover-dropdown pe-0 py-0" v-if="!this.user.login">
                    <div class="media profile-media">
                        <img class="me-2" style="border-radius:50%; width: 40px; height: 40px;" :src="this.profile_image_header" />
                        <div class="media-body">
                            <span style="font-weight: 400; font-size: 0.9rem !important;">{{ this.user.name.value }}</span>
                            <p class="mb-0" style="font-weight: 400; font-size: 0.7rem !important;"> {{ this.user.role.value }} <i class="middle fa fa-angle-down"></i></p>
                        </div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div">
                        <li>
                            <router-link :to="'/user/profile'"
                                ><vue-feather type="user"></vue-feather
                                ><span>My Account</span></router-link
                            >
                        </li>
                        <li v-if="this.user.check">
                            <router-link :to="'/dashboard/stats'"
                                ><vue-feather type="home"></vue-feather
                                ><span>My Dashboard</span></router-link
                            >
                        </li>
                        <li v-if="!this.user.check">
                            <router-link :to="'/web/homepage'"
                                ><vue-feather type="airplay"></vue-feather
                                ><span>Homepage</span></router-link
                            >
                        </li>
                        <li>
                            <a @click="userLogout()"><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { logout } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Profile",

    data() {
        return {
            isOpen: false,
            profile_image_header: null,
            user: {
                name: {
                    value: localStorage.getItem("username"),
                },
                role: {
                    value: localStorage.getItem("role"),
                },
                login: true,
                check: true,
            },
        };
    },

    watch: {
        isOpen(newVal) {
            if (newVal === true) {
                // console.log('test');
            }
            else {

            }
        },
    },

    created() {
        this.checkLogin();
        this.setProfile();
    },

    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
        },
        setProfile() {
            if (localStorage.getItem("image") == "null") {
                this.profile_image_header = require('../../assets/images/user/avatar.png');
            } else {
                this.profile_image_header = localStorage.getItem("image");
            }
        },
        checkLogin() {
            if (localStorage.getItem("token_credential") != undefined) {
                this.user.login = false;
            } else {
                this.user.login = true;
            }
            const path = [
                "/web/homepage", 
                "/web/search", 
                "/web/list-of-agent",
                "/web/aboutus",
                "/web/contactus",
                "/web/corporate",
                "/web/joinus",
                "/web/privacypolicy",
                "/web/termncondition",
                "/web/property-details",
                "/web/blog",
            ];
            const blogRegex = /^\/web\/blog\/.+/;

            if (this.$route.params.username) {
                this.user.check = true;
                return;
            }
            if (!path.includes(this.$route.path) && !blogRegex.test(this.$route.path)) {
                this.user.check = false;
                return;
            }
            this.user.check = true;
        },
        route1() {
            this.$router.push("/web/homepage");
        },
        route2() {
            this.$router.push("/web/search");
        },
        route3() {
            this.$router.push("/web/joinus");
        },
        route4() {
            this.$router.push("/web/corporate");
        },
        route5() {
            this.$router.push("/web/contactus");
        },
        route6() {
            this.$router.push("/auth/login");
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },

        async userLogout() {
            try {
                await logout().then((response) => {
                    if (response.data.success === true) {
                        localStorage.removeItem("token_expired");
                        localStorage.removeItem("token_credential");
                        localStorage.removeItem("access");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    } else {
                        // this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
              0
            }
        },
    },
};
</script>
