<style scoped>
.form-control::placeholder {
    color: #d9d9d9 !important;
}

@media (min-width: 1300px) {
    .search-table {
        width: 100%;
    }
}

.table-container {
    border: 1px solid #ccc; /* Optional: border to define scrollable area */
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}
.search-table-outter {
    overflow-x: scroll;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">
        <h4 class="py-3 m-0">My Subscription</h4>

        <div class="card rounded p-4">
            <div v-if="this.tableloader == false">

                <div class="row">
                    <div v-if="this.current_subs_id==null">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex" style="justify-content: space-between">
                            <div>
                                <div style="font-size: 0.9rem; font-weight: 400">Current subscription:</div>
                                <div style="color:#F04438;">&nbsp;No Active subscription.</div>
                            </div>
                            <div>
                                <button data-bs-toggle="modal" data-bs-target="#subscriptionModal" class="btn btn-color" type="button" style="color: #fefefe; background-color: #CA952B !important; border-color: #CA952B !important;">Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex" style="justify-content: space-between">
                            <h6>Current Subscription:</h6>
                            
                              <div v-if="this.enableChange">
                                <button @click="openSubscriptionModal" class="btn btn-color" type="button" 
                                style="color: #fefefe; background-color: #CA952B !important; border-color: #CA952B !important;">
                                Change Subscription
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-color" type="button" style="color: #fefefe; background-color: #CA952B !important; border-color: #CA952B !important;" disabled >Pending Confirmation</button>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
                            <img style="border-radius:50%; height:80px;" :src="this.current_subs_image" />
                            <div style="font-size:1rem">{{ this.current_subs_name }}</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
                            <div><h6>Number of Listing:</h6></div>
                            <div class="pt-1">{{ this.current_subs_listing }} listing</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
                            <div><h6>Plan:</h6></div>
                            <div class="pt-1">RM {{ this.current_subs_price }}</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
                            <div><h6>Package Description:</h6></div>
                            <div class="pt-1" :style="{ whiteSpace: 'pre-line' }">{{ this.current_subs_description }}</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">
                            <h6>Subscription Record:</h6>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="search-table-outter wrapper table-container">
                                <table class="search-table inner">
                                    <tr style="height: 50px; border-bottom: 1px solid #cccccc">
                                        <th style="min-width: 50px;">#</th>
                                        <th style="min-width: 250px;">Package Name</th>
                                        <th style="min-width: 150px;">Status</th>
                                        <th style="min-width: 250px;">Renewal Date</th>
                                        <th style="min-width: 150px;text-align:center">Total Price</th>
                                        <th style="min-width: 250px;text-align:center"></th>
                                    </tr>
                                    <tr v-if="this.subs_record.length == 0">
                                        <td colspan="6">
                                            <div class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                                                <div>
                                                    <img style="border-radius: 5px; width: 150px;" alt="" src="@/assets/images/no_data_image.png" />
                                                </div>
                                                <div style="text-align: center;">
                                                    <h6>No result found!</h6>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in this.subs_record" :key="index" >
                                        <td>{{ item.subscription_id }}</td>
                                        <td style="text-align:center"><div class="py-2" style="text-align: left;">{{ item.package_name }}</div></td>
                                        <td><div class="py-2;">{{ capitalizeFirstLetter(item.status) }}</div></td>
                                        <td><div>{{ convertDate(item.next_charge) }} </div></td>
                                        <td style="text-align: center"><div class="py-2" style="text-align: center;">RM {{ item.charge_amount + ' / ' + item.sub_period + ' month' }}</div></td>
                                        <td style="text-align: center"><div class="py-2" @click="viewInvoice(item.id)" style="text-align: center;cursor: pointer;color:#CA952B">View invoice</div></td>
                                    </tr>
                                </table>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="d-flex" style="flex-direction: column; justify-content: center;">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>
            </div>
        </div>


        <!-- POPUP subcription -->
     <!-- Modal Pilih Paket -->
                <div class="modal fade modal-bookmark" id="subscriptionModal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Choose Package</h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;">
                                <div class="modal-body row p-4" style="justify-content: space-between">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0" v-for="(item, index) in packageListData" :key="index">
                                        <div class="d-flex rounded p-4" style="height: 304px; box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07); justify-content: center;">
                                            <div class="p-0 row" style="width: 100%; height: 100%;">
                                                <div class="col-12 d-flex justify-content-center align-items-center">
                                                    <img :src="item.photo" alt="Package Image" style="width: 80px; border-radius: 50%;" class="package-image">
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-flex flex-column justify-content-between h-100 w-100">
                                                        <div class="pb-2">
                                                            <div style="font-size: 0.9rem; font-weight: 400; overflow-wrap: break-word;">{{ item.title }}</div>
                                                            <div class="pt-2" style="font-size: 0.8rem; font-weight: 300; overflow-wrap: break-word;">Number of Listing: {{ item.number_listing }}</div>
                                                            <div class="pt-2" style="font-size: 0.9rem; font-weight: 400; overflow-wrap: break-word;">
                                                                Price: RM {{ parseFloat(item.price_subs).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} / {{ item.period_sub }} Month
                                                            </div>
                                                            <div v-if="item.disc_sub!='0.00'" class="pt-2" style="font-size: 0.8rem; font-weight: 300; overflow-wrap: break-word;">Discount: {{ item.disc_sub }}%</div>
                                                            <div class="pt-2" style="font-size: 0.8rem; font-weight: 300; overflow-wrap: break-word;">Posting Video: {{ item.video == 1 ? 'Yes' : 'No' }}</div>
                                                        </div>
                                                        <div>
                                                         <button @click="openBankModal(item.id)" class="btn btn-color" type="button"
                                                                style="width: 100%; color: #fefefe; background-color: #CA952B !important; border-color: #CA952B !important;">
                                                                Subscribe Now
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal Pilih Bank -->
                <div class="modal fade" id="bankSelectionModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Select Bank</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"  @click="closeBankModal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Please select your bank for payment:</p>
                                <div class="py-2 col-12" style="font-size: 1rem">
                                <div style="font-weight: 450">Select Bank:</div>
                                <select v-model="selectedBank" class="form-control" required>
                                    <option value="">Choose Bank</option>
                                    <option v-for="bank in bankList" :key="bank.bank_code" :value="bank.bank_code">
                                        {{ bank . bank_name }}
                                    </option>
                                </select>
                            </div>
                </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  @click="closeBankModal" data-bs-dismiss="modal">Cancel</button>
                 <button type="button" class="btn btn-primary" @click="proceedWithSubscription">
        Proceed with Payment
    </button>

            </div>
        </div>
    </div>
</div>

    
    </div>
</template>

<script>
import {
    paymentSubscribe,
    currentSubscription,
    listPackage,
    getListBank,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import { Modal } from 'bootstrap';

export default {
    name: "My Subscription",

    components: { },

    data() {
        return {
            tableloader: true,

            current_subs_id: null,
            current_subs_name: null,
            current_subs_price: null,
            current_subs_description: null,
            current_subs_image: null,
            current_subs_listing: null,
            subs_record: null,
            enableChange: false,

            packageListData: null,
            selectedBank: "",
        selectedPackage: null, 
            bankList: [],
        };
    },

    beforeMount() {
        this.currentSubscriptionUser();
        this.loadListPackage();
        this.loadBankList();
    },

    mounted() { },

    created() { },

    computed: { },

    methods: {
        openSubscriptionModal() {
        const subscriptionModalEl = document.getElementById('subscriptionModal');
        const subscriptionModal = Modal.getInstance(subscriptionModalEl) || new Modal(subscriptionModalEl);
        subscriptionModal.show();
       
    },
        closeBankModal() {
    // Tutup modal Bank Selection
    const bankModalEl = document.getElementById('bankSelectionModal');
    const bankModal = Modal.getInstance(bankModalEl);
    if (bankModal) bankModal.hide();

    // Pastikan semua backdrop Bootstrap dihapus
    setTimeout(() => {
        document.querySelectorAll('.modal-backdrop').forEach(el => el.remove());
        document.body.classList.remove('modal-open'); // Hapus class modal-open yang bisa mengganggu scroll
        document.body.style.removeProperty('overflow'); // Pastikan overflow kembali normal
    }, 300);
},
     openBankModal(packageId) {
        const subscriptionModalEl = document.getElementById('subscriptionModal');
        const subscriptionModal = Modal.getInstance(subscriptionModalEl) || new Modal(subscriptionModalEl);
        subscriptionModal.hide();
        const bankModalEl = document.getElementById('bankSelectionModal');
        const bankModal = Modal.getInstance(bankModalEl) || new Modal(bankModalEl);
        bankModal.show();
        this.selectedPackage = packageId;
    },
      proceedWithSubscription() {
        if (!this.selectedBank) {
            this.notificationError("Please select a bank!");
            return;
        }
        this.closeBankModal();
        this.subscribePackage(this.selectedPackage);
         document.querySelectorAll('.modal-backdrop').forEach(el => el.remove());
        document.body.classList.remove('modal-open'); // Hapus class modal-open yang bisa mengganggu scroll
        document.body.style.removeProperty('overflow');
    },
        async loadBankList() {
                try {
                    const response = await getListBank();
                    console.log(response);

                    // Memeriksa jika response sukses dan status 200
                    if (response.data.success===true) {
                        this.bankList = response.data.data; // Bank data diresponse.data
                        console.log(response.data.data);
                        
                    } else {
                        this.notificationError("Failed to load bank list.");
                    }
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            },
   
        viewInvoice(id) {
            this.$router.push("/user/invoicesub?invoice=" + id);
        },
        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },
        convertDate(date) {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        },



        async loadListPackage() {
            try {
                const dataPass = {
                    status: 'active',
                };

                await listPackage(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.packageListData = response.data.data;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

      async subscribePackage(packageId) {
    try {
        if (!this.selectedBank) {
            this.notificationError("Please select a bank before proceeding!");
            return;
        }

        const dataPass = {
            agentId: localStorage.getItem("user_id"),
            packageId: packageId,
            bankCode: this.selectedBank  // Kirim bank yang dipilih
        };

        await paymentSubscribe(dataPass).then((response) => {
            if (response.data.success) {
                window.open(response.data.data.url, '_blank');
            } else {
                this.notificationError(response.data.message);
            }
        });
    } catch (error) {
        this.notificationError("Something went wrong!");
    }
},


        async currentSubscriptionUser() {
            this.tableloader = true;
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await currentSubscription(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.tableloader = false;
                        
                        if (response.data.data=='No active subscription found for this user') {

                        } else {
                            // subscription details
                            this.current_subs_id = response.data.data.subscription_details.id;

                            if (response.data.data.subscription_status=='pending') {
                                this.enableChange = false;
                            } else {
                                this.enableChange = true;
                            }
                            this.current_subs_name = response.data.data.package_details.name;
                            this.current_subs_image = response.data.data.package_details.photo;
                            this.current_subs_price = response.data.data.package_details.price;
                            this.current_subs_listing = response.data.data.package_details.listing;
                            this.current_subs_description = response.data.data.package_details.desc;
                            this.subs_record = response.data.data.history_subscription;
                        }
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        


        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
                position:"bottom-right"
            });
        },
    },
};
</script>