<template>
		<div class="row">
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="height: 620px;" :style="homeBackgroundStyle">
  <!-- Overlay Transparan -->
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 1;"></div>
  
  <!-- Konten -->
  <div class="p-4" style="position: relative; z-index: 2; color: white; text-align: left; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; height: 100%;">
    <div class="m-0 pt-3" style="font-weight: 500; font-size: 1.8rem;">Getting To Know Us</div>
    <div class="m-0 pt-4" style="font-weight: 450; font-size: 1rem;">Delivering Premium & Quality Services Since 2010</div>
    <div class="m-0 pt-2" style="font-weight: 300; font-size: 0.9rem; max-width: 700px; line-height: 1.6;">
      Land Cartel is a premier real estate agency distinguished for its exceptional services in the realm of real estate. 
      We excel in providing a comprehensive suite of real estate services with a special emphasis on the sale of new developments 
      and transactions within the secondary market. Our approach is anchored in professionalism, extensive market acumen, and a 
      deep understanding of our clients’ unique needs and aspirations, enabling us to present opportunities that align perfectly 
      with their property goals. Whether you’re seeking to buy, sell, or lease, we are committed to delivering unparalleled 
      service quality and insights, guiding you through your real estate journey to ensure a seamless and rewarding experience.
    </div>
  </div>
</div>

			<div class="pt-3 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1rem;">Vision Statement</div>
						
					<div class="pt-2" style="max-width: 600px;">
						At landcartel Properties, we envision cultivating a premier real estate ecosystem where the
						essence of constructive competition and collaborative endeavors blend to elevate
						industry standards. We aim to be a sanctuary of professional excellence, anchored
						in teamwork, trust, and divine principles, aspiring to exceed the conventional
						boundaries of service and professionalism. Our endeavor is to establish a realm of
						positivity and affluence, illuminating the pathways to success and fulfillment for
						everyone within our fold.
					</div>
				</div>
			</div>

			<div class="pt-3 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1rem;">Mission Statement</div>

					<div class="pt-2" style="max-width: 600px;">
						We are on a mission to synergize and empower our diligent team to render
						unparalleled real estate services marked by supreme professionalism and
						unwavering integrity. By embracing the values of mutual respect and divine
						guidance, we vow to create enriching experiences for our valued clients. IW
						Properties is steadfast in its commitment to being a vessel of value and wealth
						creation, ensuring that every interaction contributes to a vibrant and prosperous
						environment for our clients and collaborators alike.
					</div>
				</div>
			</div>
			
			<div class="pt-3 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1.3rem;">The Comprehensive Range of Services</div>
					<div style="font-weight: 450; font-size: 1rem;">Professional & Reliable Real Estate Consultancy Firm</div>
					<div class="pt-2" style="max-width: 600px;">
						Being a professional real estate firm, we provide all the professional services you need.
						We provide the real estate consultancy, which includes real estate agency, property and
						project management and property maintenance in the near future as we expand.
					</div>
				</div>
			</div>

			<div class="pt-4 pb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<div class="row">

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/1.png" style="width:100%"/></div>
						<div style="text-align: center;">Real State<br />Agency</div>
					</div>

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/2.png" style="width:100%"/></div>
						<div style="text-align: center;">Project<br />Marketing</div>
					</div>

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/3.png" style="width:100%"/></div>
						<div style="text-align: center;">Tenancy &<br />Leasing</div>
					</div>

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/4.png" style="width:100%"/></div>
						<div style="text-align: center;">Consultancy<br />Services</div>
					</div>

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/5.png" style="width:100%"/></div>
						<div style="text-align: center;">Retail<br />Advisory</div>
					</div>

					<div class="p-3 col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 d-flex" style="justify-content: center; align-items: center; flex-direction: column;">
						<div><img src="../../assets/images/aboutus/6.png" style="width:100%"/></div>
						<div style="text-align: center;">Property<br />Management</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<div style="width:100%;">
					<img class="img-fluid for-light" src="../../assets/images/bottom_image.png" style="width:100%" alt="loginpage"/>
				</div>
			</div>

			<div class="p-3 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1.3rem;">A Qualified & Trustworthy Real Estate Firm</div>
					<div class="pt-2" style="max-width: 600px;">
						Landcartel is legally qualified to practice Real Estate agency per Valuers, Appraisers
						& Estate Agents Act 1981. The firm is registered bearing the registration code: E (3) 1334,
						with the Board of Valuers, Appraisers & Real Estate Agents and Ministry of Finance as a
						professional firm and qualified to practice all the trade under the said Act.
					</div>
				</div>
			</div>

			<div class="p-3 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1rem;">Experience</div>
					<div class="pt-2" style="max-width: 400px;">
						Since our inception, we have carried out various
						real estate works including commercial and industrial deals.
					</div>
				</div>
			</div>

			<div class="p-3 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1rem;">Support & Governance</div>
					<div class="pt-2" style="max-width: 400px;">
						Our professionalism is backed up  by in-house
						investment, merchant banker’s and experience legal advisors.
					</div>
				</div>
			</div>
							
			<div class="p-3 col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
				<div class="d-flex p-3" style="flex-direction: column;">
					<div style="font-weight: 450; font-size: 1rem;">Scale of Fees</div>
					<div class="pt-2" style="max-width: 400px;">
						Our professional fees are based on the seventh
						schedule of Valuers, Appraisals and Estate Agents,1981.
					</div>
				</div>
			</div>

		</div>
		<div class="p-3"></div>

</template>

<script>
import homeBackground from '../../assets/images/top_image.png';
import {
    addPointUser,
	getActivityPoint
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    components: {
    },
    data() {
        return {
          selectedTab: 'Featured',
		  scrollY: 0,
		  action: null,
		  status: 0,
		  point: 0,
		  buffer: false,
        };
    },
    computed: {
        homeBackgroundStyle() {
            return {
                backgroundImage: `url(${homeBackground})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				position: 'relative',
				overflow: 'hidden',
            };
        },
    },
    mounted() {
		this.checkCondition()
		window.addEventListener("scroll", this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.handleScroll);
	},
    created() {
    },
    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "success",
				position:"bottom-right"
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "success",
				position:"bottom-right"
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
				position:"bottom-right"
            });
        },

		handleScroll() {
			this.scrollY = window.scrollY;
			if (this.scrollY >= 1000 && this.status == "1") {
				if (this.action == "Scroll") {
					this.addPoint();
				}
			}
		},
		checkPointOpen() {
			if (this.action == "Open" && this.status == "1") {
				this.addPoint();
			}
		},
        async checkCondition() {
            try {
                await getActivityPoint().then((response) => {
                    if (response.data.success === true) {
						this.action = response.data.data[2].user_action;
						this.status = response.data.data[2].status;
						this.point = response.data.data[2].limit_per_day;
						this.checkPointOpen();
                    } else {
                        // this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                // this.notificationError("Something went wrong!");
            }
        },
		async addPoint() {
            const dataPass = {
                point: this.point,
                agentId: localStorage.getItem("user_id"),
            };
			if (this.buffer == false) {
				this.buffer = true;
				try {
					await addPointUser(dataPass).then((response) => {
						if (response.data.success === true) {
							this.notificationSuccess("Congratulation your got new point!");
							setTimeout(() => {
								this.buffer = false;
							}, 2000);
							
						} else {
							this.notificationError(response.data.message);
						}
					});
				} catch (error) {
					this.buffer = false;
					// this.notificationError("Something went wrong!");
				}
			}
		},
		selectTab(tab) {
			this.selectedTab = tab;
		},
		redirectDetails() {
			this.$router.push("/setting/scheduler");
		}
    },
};
</script>
